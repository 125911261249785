<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="mobile-search">
                        <form class="w-100" method="GET" action="/shop?" @submit.prevent="search()">
                            <input type="text" placeholder="Search.." name="search" v-model="query">
                        </form>
                        <a href="#"><i class="fa fa-search"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="shop-section section pt-60 pt-lg-40 pt-md-30 pt-sm-20 pt-xs-30  pb-70 pb-lg-50 pb-md-40 pb-sm-60 pb-xs-50">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="shop-area">
                            <div class="row">
                                <div class="col-12">
                                    <!-- Grid & List View Start -->
                                    <div class="shop-topbar-wrapper d-flex justify-content-between align-items-center">
                                        <div class="d-flex">
                                            <p v-if="products.total > 0">
                                                <span class="d-none d-md-block">Showing {{ products.from }}-{{ products.to }} of {{ products.total }} results</span>
                                                <span class="d-md-none">{{ products.from }}-{{ products.to }} of {{ products.total }}</span>
                                            </p>
                                        </div>
                                        <!--Toolbar Short Area Start-->
                                        <div class="d-md-flex align-items-center">
                                            <div class="toolbar-shorter">
                                                <label class="d-none d-md-block">Sort By:</label>
                                                <select class="wide" @change="sorting()" v-model="sortValue">
                                                    <option value="1">Relevance</option>
                                                    <option value="2">Name, A to Z</option>
                                                    <option value="3">Name, Z to A</option>
                                                    <option value="4">Price, low to high</option>
                                                    <option value="5">Price, high to low</option>
                                                </select>
                                            </div>
                                        </div>
                                        <!--Toolbar Short Area End-->
                                    </div>
                                    <!-- Grid & List View End -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-3 order-lg-1 order-2">
                                    <!-- Single Sidebar Start  -->
                                    <div class="common-sidebar-widget">
                                        <h3 class="sidebar-title">Product categories</h3>
                                        <div id="MenuCategory" @click="toCategory($event)" v-html="menu">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-9 order-lg-2 order-1">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="shop-product">
                                                <div id="myTabContent-2" class="tab-content">
                                                    <div id="grid" class="tab-pane fade active show">
                                                        <div class="product-grid-view">
                                                            <div class="loading mt-5" v-if="isLoading">
                                                                <img src="/assets/images/loading.gif" >
                                                            </div>
                                                            <div class="row" v-else>
                                                                <div class="no-result" v-if="products.total == 0">
                                                                    <h4>Oops!!  Data not found</h4>
                                                                    <img src="/assets/images/no-result.svg" >
                                                                </div>
                                                                <div class="col-lg-4 col-md-6 col-6 mt-20" v-for="product in products.data" :key="product.recid_product">
                                                                    <!--  Single Grid product Start -->
                                                                    <div class="single-grid-product mb-40">
                                                                        <div class="product-image">
                                                                            <div class="product-label">
                                                                                <span v-if="product.discount > 0">-{{ product.discount_type > 0 ? product.discount : (product.discount/product.price*100).toFixed() }}%</span>
                                                                            </div>
                                                                            <router-link :to="'product/'+product.slug" class="img-box">
                                                                                <img :src="product.image_gcs.image1" class="img-fluid" alt="">
                                                                                <div class="img-placeholder"></div>
                                                                            </router-link>

                                                                            <div class="product-action">
                                                                                <ul>
                                                                                    <!-- <li>
                                                                                        <a href="#" @click.prevent="addToCart(product)"><i class="fa fa-cart-plus"></i></a>
                                                                                    </li> -->
                                                                                    <li v-if="product.tokped_link">
                                                                                        <a :href="product.tokped_link" v-if="product.tokped_link" target="_blank"><img src="/assets/images/tokopedia-qiandra.png" alt="tokopedia essential oil"></a>
                                                                                    </li>
                                                                                    <li v-if="product.shopee_link">
                                                                                        <a :href="product.shopee_link" v-if="product.shopee_link" target="_blank"><img src="/assets/images/shopee-qiandra.png" alt="tokopedia essential oil"></a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div class="product-content">
                                                                            <h3 class="title"> 
                                                                                <router-link :to="'product/'+product.slug">{{ product.name }}</router-link>
                                                                            </h3>
                                                                            <p class="product-price">
                                                                                <span class="discounted-price">Rp. {{ product.discount_type > 0 ? (product.price-(product.price*product.discount/100)).toLocaleString() :(product.price-product.discount).toLocaleString() }}</span> 
                                                                                <span class="main-price discounted" v-if="product.discount > 0">Rp. {{ product.price.toLocaleString() }}</span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <!--  Single Grid product End -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-30 mb-sm-40 mb-xs-30">
                                        <div class="col mt-5">
                                            <ul class="page-pagination" v-if="products.last_page > 1">
                                                <li v-for="link in products.links" :key="link.url"><a href="#" :disabled="link.url == null" v-html="link.label" :class="{active : link.active == true}" @click.prevent="changePage(link.url)"></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.side-menu .accordion-item{
    border: none;
}
.side-menu .accordion-button{
    background-color: transparent;
    font-weight: bold;
    color: #000;
    padding-left: 0;
}
.side-menu .accordion-body{
    padding: 10px;
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: 'ProductView',
  data(){
    return{
       sortValue:'1',
       sort:'',
       menu:'',
       get_page :  '',
       get_search : '',
       query : (this.$route.query.search) ?? ''
    }
  },
  mounted() {
      let oldScript=document.getElementById('mainJS');
      if(oldScript){
        oldScript.remove();
      }
      let mainJS = document.createElement('script')
      mainJS.setAttribute('id', 'mainJS')
      mainJS.setAttribute('src', '/assets/js/main.js')
      document.body.appendChild(mainJS);
      this.loadProduct()
      this.getCategories()
  },
  computed: {
    ...mapGetters({
        products:"products/dataProducts",
        categories:"products/categories",
        isLoading:"products/isLoading"
    }),
    urlParams(){
        return this.$route.query
    },
    category(){
        return this.$route.params.category
    }
  },
  watch: {
    urlParams: function (val) {
        this.products.links = '';
        if(this.category){
            val.category=this.category
        }else{
            val.category=''
        }
        // if((val.search)){
        //     this.get_search = '&search=' + val.search;
        // }
        // if((val.page)){
        //     this.get_page = '&page=' + val.page;
        // }
        // if((val.sort)){
        //     this.get_sort = '&sort=' + val.sort;
        // }
        // if((val.sort_type)){
        //     this.get_sort_type = '&sort_type=' + val.sort_type;
        // }
        this.getProducts(val)
    },
    categories:function (val) {
      for(var item in val){
        if(val[item].parent === 0){
            this.menu+=`<div class="accordion side-menu">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="menu${val[item].id}">
                        <a class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#submenu${val[item].id}" aria-expanded="true" aria-controls="menu${val[item].id}">
                            ${val[item].name}
                        </a>
                    </h2>
                    <div id="submenu${val[item].id}" class="accordion-collapse collapse show" aria-labelledby="menu${val[item].id}">
                        <div class="accordion-body">`;

                        this.hasMenu(val[item])
                        
            this.menu+=`</div>
                    </div>
                </div>
            </div>`
        }
      }
      //document.getElementById('MenuCategory').innerHTML=this.menu
    }
  },
  methods: {
    ...mapActions({
        getProducts:"products/getProducts",
        getCategories:"products/getCategories",
        addItemToCart:"products/addItemToCart"
    }),
    loadProduct(){
        if(this.category){
            this.urlParams.category=this.category
        }else{
            this.urlParams.category=''
        }
        if((this.urlParams.search)){
            this.get_search = '&search=' + this.urlParams.search;
        }
        if((this.urlParams.page)){
            this.get_page = '&page=' + this.urlParams.page;
        }
        this.getProducts(this.urlParams)
    },
    addToCart(item){
        item.qty=1
        this.addItemToCart(item)
        this.alertSuccess()
    },
    alertSuccess() {
            this.$swal(
                {
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: 'Success adding product to cart',
                showConfirmButton: false,
                showCloseButton: true,
                timerProgressBar: true,
                timer: 2500
                }
            );
    },
    sorting(){
       let option=this.sortValue;
       if(option == 2){
           this.sort='&sort=name&sort_type=asc';
       }else if(option == 3){
           this.sort='&sort=name&sort_type=desc';
       }else if(option == 4){
           this.sort='&sort=price&sort_type=asc';
       }else if(option == 5){
           this.sort='&sort=price&sort_type=desc';
       }else{
        this.sort=''
       }
       this.$router.push('?'+this.get_search+this.sort)
    },
    hasMenu(ItemMenu){
        let dataCategories = this.categories
        for(var item in dataCategories){
            if(ItemMenu.id === dataCategories[item].parent){
                this.menu+='<ul class="side-menu">';
                this.menu+='<li><a href="#" data-slug="'+dataCategories[item].slug+'">'+dataCategories[item].name+' ('+dataCategories[item].product_count+')</a></li>'
                //sementara di batasi hanya 2 level saja
                //this.hasMenu(dataCategories[item])
                this.menu+='</ul>';
            }
        }
    },
    toCategory(e){
        e.preventDefault()
        let slug = e.target.dataset.slug
        if(slug){
            this.$router.push('/shop/'+slug)
            this.sortValue='1'
        }
    },
    changePage(url){
        let split = url.split('page=');
        let page=split[1];
        this.$router.push('?page='+page+this.get_search+this.sort)
    },
    search(){
        if(this.currentRouteName == 'shop-by-category' || this.currentRouteName == 'shop'){
            this.$router.push('?search='+this.query)
        }else{
            this.$router.push('/shop?search='+this.query)
        }
    },
    
  },
}
</script>