<template>
     <!-- Contact Section Start -->
     <section class="ds s-py-150 mt-50 mb-50 contact-section">
        <div class="container p-50">
          <div class="row mt-50 mb-50">          
          <div class="col-md-6 col-lg-6 col-sm-12">
            <div class="contact-block wow fadeInDown" data-wow-delay="0.2s">
              <div class="map">
                  <iframe :src="contact.map" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-sm-12">
            <div class="contact-right-area wow fadeInUp" data-wow-delay="0.4s">
              <h2 class="contact-title">Kontak Kami</h2>
              <div class="contact-right">
                <div class="single-contact">
                  <div class="contact-icon">
                    <i class="fa-solid fa-location-dot"></i>
                  </div>
                  <p>{{ contact.address }}</p>
                </div>
                <div class="single-contact">
                  <div class="contact-icon">
                    <i class="fa-regular fa-envelope"></i>
                  </div>
                  <p><a href="mailto:irfancahyandra10@gmail.com">Email:  {{ contact.email }}</a></p>
                </div>
                <div class="single-contact">
                  <div class="contact-icon">
                    <i class="fa-solid fa-phone"></i>
                  </div>
                  <p><a href="tel:+6287834014781">Phone:  {{ contact.phone }}</a></p>
                </div>
                <div class="single-contact">
                  <div class="contact-icon">
                    <i class="fa-brands fa-whatsapp"></i>
                  </div>
                  <p><a href="https://wa.me/6287834014781?text=Hallo%2C%0Asaya%20mau%20penawaran%20Essential%20Oil" target="_blank">Whatsapp:  +{{ contact.whatsapp }}</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </section>
    <!-- Contact Section End -->
</template>
<script>
import $http from '../api';
export default {
    name: 'ContactPage',
    data(){
        return{
           contact:''
        }
    },
    mounted() {
        let oldScript=document.getElementById('mainJS');
        if(oldScript){
            oldScript.remove();
        }
        this.getContactContent()
        let mainJS = document.createElement('script')
        mainJS.setAttribute('id', 'mainJS')
        mainJS.setAttribute('src', 'assets/js/main.js')
        document.body.appendChild(mainJS);
    },
    methods:{
      async getContactContent(){
          let endpoint = 'content/contact'
          await $http.get(endpoint)
              .then((response) => {
                  this.contact=response.data.message;
              })
              .catch((error) => {
                  console.log(error)
              });
      },
    },
    computed:{
        
    },
    watch: {
       
    },
}
</script>
