import $http from '../api';
export default {
    namespaced: true,
    state: {
        loading: false,
        loadingHeader: false,
        homeProducts: [],//untuk produk yang tampil di depan
        products: [],
        productDetails: {},
        productCategory: {},
        categories: {},
        cart: (localStorage.getItem('cartMemoire')) ? JSON.parse(localStorage.getItem('cartMemoire')) : [],
    },
    getters: {
        isLoading: (state) => state.loading,
        isLoadingHeader: (state) => state.loadingHeader,
        dataHomeProducts: (state) => state.homeProducts,
        dataProducts: (state) => state.products,
        productDetails: (state) => state.productDetails,
        productCategory: (state) => state.productCategory, //harus di keluarkan karna nested object terbaca undifined
        categories: (state) => state.categories,
        cart: (state) => state.cart,
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
        setLoadingHeader(state, payload) {
            state.loadingHeader = payload
        },
        setHomeProducts(state, payload) {
            state.homeProducts = payload
        },
        setProducts(state, payload) {
            state.products = payload
        },
        setProductDetails(state, payload) {
            state.productDetails = payload
        },
        setCategory(state, payload) {
            state.productCategory = payload
        },
        setCategories(state, payload) {
            state.categories = payload
        },
        setCart(state, payload) {
            state.cart = payload
        },
        addToCart(state, item) {

            let newQty = 1
            if (item.qty) {
                newQty = item.qty
            }
            const productInCart = state.cart.find((products) => products.recid_product === item.recid_product);
            if (!productInCart) {
                state.cart.push({ ...item, qty: newQty });
            } else {
                productInCart.qty += newQty;
                productInCart.updated_at = Math.floor(new Date().getTime() / 1000);
            }

            if (!localStorage.getItem('access_token')) { // add local cart
                localStorage.setItem('cartMemoire', JSON.stringify(state.cart))
                state.cart = (localStorage.getItem('cartMemoire')) ? JSON.parse(localStorage.getItem('cartMemoire')) : []
            }
        },
        removeFromCart(state, id) {
            state.cart = state.cart.filter((item) => item.recid_product !== id);
            localStorage.setItem('cartMemoire', JSON.stringify(state.cart))
        },
        selectItem(state, item) {
            const productInCart = state.cart.find((product) => product.recid_product === item.id);
            productInCart.selected = item.status;
            localStorage.setItem('cartMemoire', JSON.stringify(state.cart))
        },
        updateQty(state, item) {
            const productInCart = state.cart.find((product) => product.recid_product === item.id);
            productInCart.qty = item.qty;
            productInCart.updated_at = Math.floor(new Date().getTime() / 1000);
            localStorage.setItem('cartMemoire', JSON.stringify(state.cart))
        },
        addQty(state, id) {
            const productInCart = state.cart.find((product) => product.recid_product === id);
            productInCart.qty++;
            productInCart.updated_at = Math.floor(new Date().getTime() / 1000);
            localStorage.setItem('cartMemoire', JSON.stringify(state.cart))
        },
        reduceQty(state, id) {
            const productInCart = state.cart.find((product) => product.recid_product === id);
            if (productInCart.qty > 1) {
                productInCart.qty--;
                productInCart.updated_at = Math.floor(new Date().getTime() / 1000);
            } else {
                state.cart.splice(state.cart.indexOf(productInCart, 1));
            }
            localStorage.setItem('cartMemoire', JSON.stringify(state.cart))
        },
        emptyCart(state) {
            state.cart = []
            localStorage.removeItem('cartMemoire')
        }
    },
    actions: {
        async getHomeProducts({ commit }) {
            commit('setLoading', true);
            let endpoint = 'products/index?limit=10&sort=created_at&sort_type=desc'
            await $http.get(endpoint)
                .then((response) => {
                    commit('setHomeProducts', response.data.message);
                    commit('setLoading', false);
                    // console.log(response.data.message)
                })
                .catch((error) => {
                    console.log(error)
                });
        },
        async getProducts({ commit }, params) {
            let page = '';
            let search = '';
            let sort = '';
            let sort_type = '';
            let bycategory = '';
            if (params.page) {
                page = 'page=' + params.page
            }
            if (params.category) {
                bycategory = '&category=' + params.category
            }
            if (params.search) {
                search = '&search=' + params.search;
            }
            if (params.sort) {
                sort = '&sort=' + params.sort;
            }
            if (params.sort_type) {
                sort_type = '&sort_type=' + params.sort_type;
            }
            commit('setLoading', true);
            let endpoint = 'products/index?' + page + bycategory + search + sort + sort_type
            await $http.get(endpoint)
                .then((response) => {
                    commit('setProducts', response.data.message);
                    commit('setLoading', false);
                })
                .catch((error) => {
                    console.log(error)
                });

        },
        async getProductDetails({ commit }, slug) {
            commit('setLoading', true);
            let endpoint = 'products/detail/' + slug
            await $http.get(endpoint)
                .then((response) => {
                    commit('setProductDetails', response.data.message);
                    commit('setLoading', false);
                    commit('setCategory', response.data.message.category.name)
                    let oldScript = document.getElementById('sliderJS');
                    if (oldScript) {
                        oldScript.remove();
                    }
                    let sliderJS = document.createElement('script')
                    sliderJS.setAttribute('id', 'sliderJS')
                    sliderJS.setAttribute('src', '/assets/js/product-detail.js')
                    document.body.appendChild(sliderJS);
                })
                .catch((error) => {
                    console.log(error)
                });

        },
        async getCategories({ commit }) {
            let endpoint = 'categories'
            await $http.get(endpoint)
                .then((response) => {
                    commit('setCategories', response.data.message);
                    //console.log(response.data.message)
                })
                .catch((error) => {
                    console.log(error)
                });
        },
        async addItemToCart({ commit }, item) {
            let CartItem = {
                recid_product: item.recid_product,
                qty: item.qty,
                selected: 0,
                created_at: Math.floor(new Date().getTime() / 1000),
                updated_at: null,
            }
            if (localStorage.getItem('access_token')) {
                let endpoint = 'carts/add'
                let form = {
                    item: CartItem,
                }
                await $http.post(endpoint, form)
                    .then((response) => {
                        console.log(response.data.message)
                        commit("addToCart", CartItem);  //harus di pisahkan untuk await response db
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            } else {
                commit("addToCart", CartItem); 
            }



        },
        async removeItemFromCart({ commit }, id) {
            if (localStorage.getItem('access_token')) {
                let endpoint = 'carts/remove/' + id
                await $http.delete(endpoint)
                    .then(() => {
                        commit("removeFromCart", id);  //harus di pisahkan untuk await response db
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            } else {
                commit("removeFromCart", id);
            }
        },
        async selectItem({ commit }, item) {
            if (localStorage.getItem('access_token')) {
                let endpoint = 'carts/select/' + item.id
                let form = {
                    status: item.status,
                }
                await $http.post(endpoint, form)
                    .then(() => {
                        commit("selectItem", item);  //harus di pisahkan untuk await response db
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            } else {
                commit("selectItem", item);
            }
        },
        async updateQty({ commit }, item) {
            if (localStorage.getItem('access_token')) {
                let endpoint = 'carts/update'
                let form = {
                    product: item.id,
                    qty: item.qty
                }
                await $http.post(endpoint, form)
                    .then(() => {
                        commit("updateQty", item);  //harus di pisahkan untuk await response db
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            } else {
                commit("updateQty", item);
            }
        },
        addQty({ commit }, id) {
            commit("addQty", id);
        },
        reduceQty({ commit }, id) {
            commit("reduceQty", id);
        },
        emptyCart({ commit }) {
            commit("emptyCart");
        },
        setLoading({ commit }, bool) {
            commit('setLoading', bool);
        },
        setLoadingHeader({ commit }, bool) {
            commit('setLoadingHeader', bool);
        },
        async syncCart({ commit }) {
            if (localStorage.getItem('access_token')) {
                let cart = JSON.parse(localStorage.getItem('cartMemoire'))
                if (cart) {
                    let endpoint = 'synccart'
                    let form = {
                        email: JSON.parse(localStorage.getItem('user_info')).email,
                        cart
                    }
                    await $http.post(endpoint, form)
                        .then(() => {
                            commit("emptyCart");
                        })
                        .catch((error) => {
                            console.log(error)
                        });
                }
            }
        },
        async getCartFromDb({ commit }) {
            let email = JSON.parse(localStorage.getItem('user_info')).email
            let endpoint = 'getcartfromdb/' + email
            await $http.get(endpoint)
                .then((response) => {
                    commit("setCart", response.data.message)
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    }
}
