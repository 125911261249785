<template>
    <div class="container p-50">
        <div class="row mt-50 mb-50">

            <!--About Us Area Start-->
            <div class="about-us-area section pt-90 pt-lg-70 pt-md-60 pt-sm-50 pt-xs-45 pb-70 pb-lg-50 pb-md-60 pb-sm-60 pb-xs-50">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-12">
                            <!--About Us Image Start-->
                            <div class="about-us-img-wrapper mb-30 mb-xs-15">
                                <div class="about-us-image img-full">
                                    <img src="/assets/images/supplier-essential-oil.jpg" alt="supplier-essential-oil-minyak-atsiri" class="wow fadeInLeft" ata-wow-delay="0.2s">
                                </div>
                            </div>
                            <!--About Us Image End-->
                        </div>
                        <div class="col-lg-6 col-12">
                            <!--About Us Content Start-->
                            <div class="about-us-content pl-30 pl-md-0 pl-sm-0 pl-xs-0">
                                <h2 class="wow fadeInRight" data-wow-delay="0.4s">Kami Sebagai Produsen Essential Oil Yang Konsisten</h2>
                                <p class="wow fadeInUp" data-wow-delay="0.6s">Selain menghasilkan produk untuk kalangan pengguna yang ingin mendapatkan manfaat langsung dari kemurnian essential oil,
                                 kami juga telah berpengalaman menjalin kerjasama dengan berbagai perusahaan di banyak bidang industri sebagai supplier yang terpercaya. Kami siap memenuhi kebutuhan berbagai jenis Essential Oil atau minyak atsiri seperti <b>Minyak Cengkeh (Clove Oil), Minyak Sereh Wangi (Citronella Oil), Minyak Nilam (Patchouli Oil)</b> dan minyak lain nya yang berasal dari seluruh daerah di indonesia.</p>
                            </div>
                            <!--About Us Content End-->
                        </div>
                    </div>
                </div>
            </div>
            <!--About Us Area End-->

            <!--Features section start-->
            <div class="features-section section pt-30 pt-lg-15 pt-md-0 pt-sm-0 pt-xs-15 mt-50">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-10 mt-50 mb-50 wow fadeInDown" data-wow-delay="0.2s">
                            <h2 class="text-center mb-20 section-title">Mengapa Memilih Kami ?</h2>
                            <p class="text-center">
                                Kami adalah Supplier yang siap memenuhi Kebutuhan berbagai jenis Essential Oil atau Minyak Atsiri dalam skala kecil maupun besar untuk berbagai kalangan seperti perusahaan, produsen, distributtor dan pemakai Di Indonesia Serta Luar Negeri.
                                Kami memiliki tujuan <b>"Menjadi Supplier yang menghasilkan Essential Oil atau Minyak Atsiri dengan kualitas terbaik Sehingga dapat menjalin kerjasama berkelanjutan dengan semua mitra“</b>.
                            </p>
                        </div>
                        <div class="col-lg-4 col-md-6 mt-30">
                            <!-- Single Feature Start -->
                            <div class="feature mb-30 wow fadeInDown" data-wow-delay="0.4s">
                                <div class="feature-image">
                                    <i class="fa-solid fa-medal"></i>
                                </div>
                                <div class="">
                                    <h4 class="title">KUALITAS & HARGA</h4>
                                    <p class="short-desc">Jaminan kualitas produk yang terbaik, dengan harga yang kompetitif</p>
                                </div>
                            </div>
                            <!-- Single Feature End -->
                        </div>
                        <div class="col-lg-4 col-md-6 mt-30">
                            <!-- Single Feature Start -->
                            <div class="feature  mb-30 wow fadeInDown" data-wow-delay="0.4s">
                                <div class="feature-image">
                                    <i class="fa-solid fa-hand-holding-droplet"></i>
                                </div>
                                <div class="">
                                    <h4 class="title">Pengalaman</h4>
                                    <p class="short-desc">Kami memiliki banyak pengalaman kontrak kerjasama dengan perusahaan-perusahaan eksportir minyak atsiri di Indonesia</p>
                                </div>
                            </div>
                            <!-- Single Feature End -->
                        </div>
                        <div class="col-lg-4 col-md-6 mt-30">
                            <!-- Single Feature Start -->
                            <div class="feature  mb-30 wow fadeInDown" data-wow-delay="0.4s">
                                <div class="feature-image">
                                    <i class="fa-solid fa-store"></i>
                                </div>
                                <div class="">
                                    <h4 class="title">PRO UMKM</h4>
                                    <p class="short-desc">Memberikan dukungan penuh untuk UMKN, Home Industri yang ingin memulai bisnis Essential Oil</p>
                                </div>
                            </div>
                            <!-- Single Feature End -->
                        </div>
                        <div class="col-lg-4 col-md-6 mt-30">
                            <!-- Single Feature Start -->
                            <div class="feature  mb-30 wow fadeInDown" data-wow-delay="0.6s">
                                <div class="feature-image">
                                    <i class="fa-solid fa-warehouse"></i>
                                </div>
                                <div class="">
                                    <h4 class="title">KAPASITAS PRODUKSI</h4>
                                    <p class="short-desc">Kami Mempunyai banyak mesin penyulingan di berbagai daerah Jawa Barat, Jawa Tengah dan Sumatera sehingga dapat memenuhi kebutuhan dalam jumlah besar.</p>
                                </div>
                            </div>
                            <!-- Single Feature End -->
                        </div>
                        <div class="col-lg-4 col-md-6 mt-30">
                            <!-- Single Feature Start -->
                            <div class="feature mb-30 wow fadeInDown" data-wow-delay="0.6s">
                                <div class="feature-image">
                                    <i class="fa-solid fa-bottle-droplet"></i>
                                </div>
                                <div class="">
                                    <h4 class="title">TANPA MINIMUM ORDER</h4>
                                    <p class="short-desc">Menerima Order dalam Quantity Besar Kecil tanpa minimum order</p>
                                </div>
                            </div>
                            <!-- Single Feature End -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Features section end-->
    <div class="row justify-content-between bg-soft-green" id="counter">
        <div class="container">
            <div class="row">
                <!-- Start counter -->
                <div class="col-lg-3 col-md-6 col-xs-12">
                    <div class="counter-box py-5 wow fadeInUp" data-wow-delay="0.2s">
                    <div class="icon-o"><i class="fa-solid fa-droplet"></i></div>
                    <div class="fact-count">
                        <h3><span class="counter">60000</span> kg</h3>
                        <p>Produksi Minyak</p>
                    </div>
                    </div>
                </div>
                <!-- End counter -->
                <!-- Start counter -->
                <div class="col-lg-3 col-md-6 col-xs-12">
                    <div class="counter-box py-5 wow fadeInUp" data-wow-delay="0.4s">
                    <div class="icon-o"><i class="fa-solid fa-users"></i></div>
                    <div class="fact-count">
                        <h3><span class="counter">270</span></h3>
                        <p>Client & Mitra</p>
                    </div>
                    </div>
                </div>
                <!-- End counter -->
                <!-- Start counter -->
                <div class="col-lg-3 col-md-6 col-xs-12">
                    <div class="counter-box py-5 wow fadeInUp" data-wow-delay="0.6s">
                    <div class="icon-o"><i class="fa-solid fa-seedling"></i></div>
                    <div class="fact-count">
                        <h3><span class="counter">1200</span> Ha</h3>
                        <p>Lahan Pertanian</p>
                    </div>
                    </div>
                </div>
                <!-- End counter -->
                <!-- Start counter -->
                <div class="col-lg-3 col-md-6 col-xs-12">
                    <div class="counter-box py-5 wow fadeInUp" data-wow-delay="0.8s">
                    <div class="icon-o"><i class="fa-solid fa-bong"></i></div>
                    <div class="fact-count">
                        <h3><span class="counter">12</span></h3>
                        <p>Tempat Penyulingan</p>
                    </div>
                    </div>
                </div>
                <!-- End counter -->
            </div>
            <div class="row mt-50 mb-50">
                <div class="col-md-12 text-center">
                    <a href="https://wa.me/6287834014781?text=Hallo%2C%0Asaya%20butuh%20supply%20Essential%20Oil" target="_blank" class="btn btn-primary wow fadeInUp" data-wow-delay="1s">Mari Bekerjasama</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CorporateArea',
    data(){
        return{
           
        }
    },
    mounted() {
        let oldScript=document.getElementById('mainJS');
        if(oldScript){
            oldScript.remove();
        }
        let mainJS = document.createElement('script')
        mainJS.setAttribute('id', 'mainJS')
        mainJS.setAttribute('src', 'assets/js/main.js')
        document.body.appendChild(mainJS);
    },
    methods:{
        
    },
    computed:{
        
    },
    watch: {
       
    },
}
</script>
