import $http from '../api';
// import products from './products'
export default {
    namespaced: true,
    state: {
        StatusRegister: null,
        StatusLogin: null,
        access_token: localStorage.getItem('access_token'),
        user_info: (localStorage.getItem('user_info')) ? JSON.parse(localStorage.getItem('user_info')) : []
    },
    getters: {
        StatusRegister: (state) => state.StatusRegister, 
        StatusLogin: (state) => state.StatusLogin, 
        user_info: (state) => state.user_info,
        access_token: (state) => state.access_token,
        isAuth: (state) => {
            if (state.access_token != "" && state.access_token != null) {
                return true
            } else {
                return false
            }
        }

    },
    mutations: {
        setStatusRegister(state, payload) {
            state.StatusRegister = payload
        },
        setStatusLogin(state, payload) {
            state.StatusLogin = payload
        },
        set_access_token(state, payload) {
            state.access_token = payload
        },
        set_user_info(state, payload) {
            state.user_info = payload
        },
        unset_access_token(state) {
            state.access_token = null
        }
    },
    actions: {
        async register({ commit }, form) {
            let endpoint = 'auth/register'
            await $http.post(endpoint, form)
            .then((response) => {
                commit('setStatusRegister', response); //harus ada action commit
            })
            .catch((error) => {
                console.log(error)
            });

        },
        async login({ commit }, form) {
            let endpoint = 'auth/login'
            await $http.post(endpoint, form)
                .then((response) => {

                    localStorage.setItem('access_token', response.data.access_token);
                    localStorage.setItem('loginStatus', "success");

                    let user_info = {
                        name: response.data.name,
                        email: response.data.email,
                        phone: response.data.phone,
                        image: response.data.image,
                        image_gcs: response.data.image_gcs,
                    }
                    localStorage.setItem('user_info', JSON.stringify(user_info))

                    commit('set_access_token', response.data.access_token);
                    commit('set_user_info', user_info)
                    // products.actions.syncCart();
                    //console.log(response)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        commit('setStatusLogin', error.response.status);
                    } else {
                        console.log(error)
                    }
                });

        },
        logout({ commit }) {
            commit('unset_access_token')
            localStorage.removeItem('access_token')
            localStorage.removeItem('loginStatus')
            localStorage.removeItem('user_info')
        }
    }
}
