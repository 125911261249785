import Vuex from 'vuex'
import products from './products'
import auth from './auth'
import account from './account'

export default new Vuex.Store({
    modules: {
        products,
        auth,
        account
    }
})