import $http from '../api';
export default {
    namespaced: true,
    state: {
        provinsi: [],
        kota: [],
        alamat: [],
        transactionLoading: false,
        transaction: [],
        pendingTransaction: ''
    },
    getters: {
        provinsi: (state) => state.provinsi, 
        kota: (state) => state.kota,
        alamat: (state) => state.alamat, 
        transactionLoading: (state) => state.transactionLoading,
        transactions: (state) => state.transactions,
        pendingTransaction: (state) => state.pendingTransaction, 
    },
    mutations: {
        setProvinsi(state, payload) {
            state.provinsi = payload
        },
        setKota(state, payload) {
            state.kota = payload
        },
        setAlamat(state, payload) {
            state.alamat = payload
        },
        setTransactionLoading(state, payload) {
            state.transactionLoading = payload
        },
        setTransactions(state, payload) {
            state.transactions = payload
        },
        setPendingTransaction(state, payload) {
            state.pendingTransaction = payload
        },

    },
    actions: {
        async _setProvinsi({ commit }) {
            let endpoint = 'address/province'
            await $http.get(endpoint)
            .then((response) => {
                commit('setProvinsi', response.data.data);
            })
            .catch((error) => {
                console.log(error)
            });
        },
        async _setKota({ commit },provinsi) {
            let endpoint = 'address/city/' + provinsi
            await $http.get(endpoint)
            .then((response) => {
                commit('setKota', response.data.data);
            })
            .catch((error) => {
                console.log(error)
            });
        },
        async getAlamat({ commit }) {
            let endpoint = 'address/get'
            await $http.get(endpoint)
                .then((response) => {
                    commit('setAlamat', response.data.message);
                })
                .catch((error) => {
                    console.log(error)
                });
        },
        async getTransaction({ commit }) {
            commit('setTransactionLoading', true);
            let endpoint = 'transaction'
            await $http.get(endpoint)
                .then((response) => {
                    commit('setTransactions', response.data.message);
                    commit('setTransactionLoading', false);
                })
                .catch((error) => {
                    console.log(error)
                    commit('setTransactionLoading', false);
                });
        },
        async getPendingTransaction({ commit }) {
            let endpoint = 'transaction/pending'
            await $http.get(endpoint)
                .then((response) => {
                    commit('setPendingTransaction', response.data.data);
                })
                .catch((error) => {
                    console.log(error)
                });
        },
    }
}