<template>
    <div>
        <div class="row content">
            <div class="col-md-12" v-if="loading">
                 <img src="/assets/images/loader2.gif" class="img-loader">
            </div>
            <div class="col-md-12" v-else>
                <div class="card transaction-card box-shadow" v-for="transaction in dataTransaction.data" :key="transaction.recid_transaction">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-8">
                                <span class="transaction-date">{{ transaction.created_at  }}</span>{{ transaction.id_transaction }}
                            </div>
                            <div class="col-4">
                                <span class="badge border border-default text-dark float-end" v-if="transaction.status == 1">Menuggu Pembayaran</span>
                                <span class="badge border border-warning text-warning float-end" v-if="transaction.status == 2">Diprosess</span>
                                <span class="badge border border-primary text-primary float-end" v-if="transaction.status == 3">Dikirim</span>
                                <span class="badge border border-success text-success float-end" v-if="transaction.status == 4">Selesai</span>
                                <span class="badge border border-danger text-danger float-end" v-if="transaction.status == 5">Batal</span>
                                <span class="badge border border-danger text-danger float-end" v-if="transaction.status == 6">Kadaluarsa</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <table class="table mt-4">
                            <tr v-for="item in transaction.transaction_detail" :key="item.recid_product">
                                <td style="width:80px;" class="transaction-thumbnail">
                                    <a href="">
                                        <img :src="item.product.image_gcs.image1" class="cart-thumbnail" :alt="item.product.slug">
                                    </a>
                                </td>
                                <td>
                                    <h3 class="product-name"><router-link :to="'/product/'+item.product.slug">{{ item.product.name }}</router-link></h3>
                                    <div class="d-block d-md-none p-0 pb-4">
                                        <span class="product-quantity">{{ item.qty }} × </span>
                                        <span class="product-price">Rp. {{ (item.price-item.discount).toLocaleString() }}</span>
                                    </div>
                                </td>
                                <td class="text-end d-none d-md-block">
                                    <span class="product-quantity">{{ item.qty }} × </span>
                                    <span class="product-price">Rp. {{ (item.price-item.discount).toLocaleString() }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" class="text-end"><br></td>
                            </tr>
                            <tr>
                                <td colspan="3" class="text-end"><span>{{ transaction.carrier }}</span></td>
                            </tr>
                            <tr>
                                <td colspan="3" class="text-end"><span class="total-transaction">Total : RP. {{ transaction.total_amount.toLocaleString() }}</span></td>
                            </tr>
                        </table>
                        
                    </div>
                    <div class="card-footer py-3 border-top tracking-section">
                        <div class="row">
                            <div class="col-6">

                                <span @click="copyResi(transaction.tracking_no)">No. Resi : </span><span class="resi" @click="copyResi(transaction.tracking_no)">{{ transaction.tracking_no }}</span> <span @click="copyResi(transaction.tracking_no)"><i class="fa-solid fa-copy"></i></span>
                            </div>
                            <div class="col-6 text-end">
                                <a href="https://www.jne.co.id/id/tracking/trace" @click="copyResi(transaction.tracking_no)" target="_blank" class="btn btn-success btn-tracking mt-3 mt-md-0" v-if="transaction.tracking_no && transaction.status == 3">Lacak</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapActions } from "vuex";
export default {
    name: 'TransactionComponent',
    data() {
        return {
            dataTransaction:[]
        };
    },
    methods:{
        ...mapActions({
            getTransaction:"account/getTransaction",
        }),
        copyResi(text){
            navigator.clipboard.writeText(text);
            this.$swal(
            {
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: 'Nomor Resi Berhasil Disalin !!',
                showConfirmButton: false,
                timer: 3500
                }
            );
        }
    },
    watch:{
        transactions:function(val){
            this.dataTransaction=val
        }
    },
    computed:{
        ...mapGetters({
            transactions:"account/transactions",
            loading:"account/transactionLoading"
        }),
    },
     mounted(){
        this.getTransaction()
     }
}
</script>
