<template>
    <section class="ds s-py-150">
        <div class="container p-50">
            <div class="row mt-50 mb-50">
               <div class="col-md-6 d-none d-md-block">
                    <img src="/assets/images/login.svg" class="login-img" alt="">
               </div>
               <div class="col-md-6">
                    <div class="login-box">
                        <h3 class="text-center">Login Akun</h3>
                        <p class="text-center">Belum punya akun Qiandra? <router-link to="/register">Daftar</router-link></p>
                        <form action="?" @submit.prevent="loginValidation">
                            <div v-if="registerStatus == 'success'" class="alert alert-success alert-dismissible text-center" role="alert">
                                Akun anda berhasil di daftarkan ! <strong>silahkan Login</strong>
                            </div>
                            <div v-if="StatusLogin == 401" class="alert alert-danger alert-dismissible text-center" role="alert">
                                <strong>Login Gagal !</strong> Email atau password tidak sesuai.
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input type="email" id="formEmail" class="form-control" v-model="form.email" required placeholder="name@example.com">
                                        <span id="warningEmail" class="form-warning d-none">Format email tidak sesuai</span>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Password</label>
                                        <input type="password" id="formPassword" class="form-control" v-model="form.password" required>
                                        <span id="warningPassword" class="form-warning d-none">Passwor minimal 8 Caracter </span>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <button type="submit" class="btn form-control mt-20" id="btnLogin">Masuk</button>
                                </div>
                            </div>
                        </form>
                    </div>
               </div>
            </div>
        </div>
    </section>
</template>
<script>
import { mapActions,mapGetters } from "vuex";
import validator from 'validator';
export default {
    name: 'LoginPage',
    data(){
        return{
            form : {
                email:'',
                password:''
            },
            registerStatus: localStorage.getItem('registerStatus')
        }
    },
    mounted() {
        let oldScript=document.getElementById('mainJS');
        if(oldScript){
            oldScript.remove();
        }
        let mainJS = document.createElement('script')
        mainJS.setAttribute('id', 'mainJS')
        mainJS.setAttribute('src', 'assets/js/main.js')
        document.body.appendChild(mainJS);
        if(this.registerStatus == 'success'){
            this.$swal(
                {
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: 'Akun berhasil di daftarkan',
                showConfirmButton: false,
                timer: 2500
                }
            );
            localStorage.removeItem('registerStatus')
        }
        if(this.access_token != '' && this.access_token != null){
            this.$router.push('/')
        }
    },
    methods:{
        loginValidation(){
            let formEmail=document.getElementById('formEmail');
            document.getElementById('btnLogin').disabled=true;
            if(validator.isEmail(this.form.email)){
                formEmail.classList.remove('failed-validate')
                document.getElementById('warningEmail').classList.add('d-none')
                this.login(this.form)
                document.getElementById('btnLogin').disabled=false;
            }else{
               formEmail.classList.add('failed-validate');
               document.getElementById('warningEmail').classList.remove('d-none')
               document.getElementById('btnLogin').disabled=false;
            }
        },
        ...mapActions({
            login:"auth/login",
            syncCart:"products/syncCart",
            getCartFromDb:"products/getCartFromDb",
        }),
    },
    computed:{
         ...mapGetters({
                access_token:"auth/access_token",
                StatusLogin:"auth/StatusLogin"
        }),
    },
    watch: {
        access_token:function (val) {
            if(val != '' && val != null){
               this.syncCart()
               this.getCartFromDb()
               this.$router.push('/')
            }
        }
    },
}
</script>
