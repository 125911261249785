<template>
   <div id="main-wrapper">
        <HeaderComponent />
        <router-view/>
        <FooterComponent />
    </div>
</template>
<script>
import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
export default {
  components:{
    HeaderComponent,
    FooterComponent
  }
}
</script>
