<template>
    <section class="ds s-py-150">
        <div class="container p-50">
            <div class="row mt-30 mt-md-50 mb-50">
               <div class="col-md-12 d-none d-md-block">
                <h2 class="mb-20">Akun Saya</h2>
               </div>
               <div class="col-md-12">
                <div class="card account-card box-shadow">
                    <div class="row">
                        <div class="col-12 col-md-3 pt-5 pb-md-5 px-md-5">
                            <ul class="nav">
                                <li class="nav-item">
                                    <a class="nav-link" :class="{active: currentRouteName === 'account'}" id="profile" data-bs-toggle="pill" href="#v-profile" role="tab" aria-controls="v-profile" aria-selected="true" aria-current="page" @click="pushRoute('/account')"><i class="fa-regular fa-user"></i> Profile</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="address" data-bs-toggle="pill" href="#v-address" role="tab" aria-controls="v-address" aria-selected="false" @click="getAlamat();pushRoute('/account')"><i class="fa-solid fa-location-dot"></i> Alamat</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" :class="{active: currentRouteName === 'transaction'}" id="transaction" data-bs-toggle="pill" href="#v-transaction" role="tab" aria-controls="v-transaction" aria-selected="false" @click="pushRoute('/account/transaction')"><i class="fa-solid fa-rupiah-sign"></i> Transaksi</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 col-md-9">
                            <div class="tab-content my-3 p-2 p-md-5" id="v-tabContent">
                                <div class="tab-pane fade" :class="{'show active': currentRouteName === 'account'}" id="v-profile" role="tabpanel" aria-labelledby="profile">
                                    <div class="row content">
                                        <div class="col-md-4">
                                            <img :src="user_info.image_gcs" class="profile-img">
                                            <button class="btn btn-defult btn-sm form-control mt-20"><i class="fa-solid fa-lock"></i> Ganti Password</button>
                                            
                                        </div>
                                        <div class="col-md-8">
                                            <table class="table profile-info">
                                                <tr>
                                                    <td class="label">Nama</td>
                                                    <td>
                                                        <p v-if="!showInput">{{ user_info.name }}</p>
                                                        <input class="form-control" v-model="user_info.name" v-else @blur="showInput = true">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">No. HP</td>
                                                    <td>
                                                        <p v-if="!showInput">{{ user_info.phone }}</p>
                                                        <input class="form-control" v-model="user_info.phone" v-else @blur="showInput = true">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">Email</td>
                                                    <td>{{ user_info.email }}</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <button class="btn btn-sm btn-primary mt-2 form-control" @click="showInput = true" v-if="!showInput"><i class="fa-solid fa-pencil"></i> Edit Profile</button>
                                                        <div class="row" v-else>
                                                            <div class="col-md-6">
                                                                <button class="btn btn-default btn-sm form-control" @click="showInput = false"><i class="fa-solid fa-times"></i> Cancel</button>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <button class="btn btn-defult btn-sm form-control" @click="updateProfile()"><i class="fa-solid fa-save"></i> UPDATE</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="v-address" role="tabpanel" aria-labelledby="address">
                                    <div class="row mb-10 p-2 p-md-0">
                                        <div class="col-6">
                                            <h4>Daftar Alamat</h4>
                                        </div>
                                        <div class="col-6">
                                            <a class="text-success float-end" href="#" data-bs-toggle="modal" data-bs-target="#newAddress" @click="getProvince();"><i class="fa-solid fa-plus"></i> Tambah <span class="d-none d-md-inline">Alamat</span></a>
                                        </div>
                                        <div class="col-md-12"><div class="border-bottom mt-3 mb-md-3"></div></div>
                                    </div>
                                   <div class="row content">
                                        <div class="col-md-12" v-if="alamat">
                                            <div class="card address-card box-shadow" v-for="_alamat in alamat" :key="_alamat.id">
                                                <div class="label d-none d-md-block">{{ _alamat.address_label }}</div>
                                                <h2>{{ _alamat.name_receiver }} <span class="address-phone-number">{{ _alamat.phone_receiver }}</span></h2>
                                                <p>{{ _alamat.detail_address }} <br> {{ _alamat.city+' - '+_alamat.province }}</p>
                                                <div>
                                                    <span class="label d-md-none">{{ _alamat.address_label }}</span>
                                                    <a href="#" class="float-end" @click="deleteAddress(_alamat.id)"><i class="fa-solid fa-trash"></i> Hapus</a>
                                                    <a href="#updateAdress" class="mx-3 float-end border-end px-3" data-bs-toggle="modal" @click="getProvince();editAddress(_alamat.id)"><i class="fa-solid fa-pencil"></i> Ubah</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12" v-else>
                                            <img src="/assets/images/loader2.gif" class="img-loader">
                                        </div>
                                   </div>
                                </div>
                                <div class="tab-pane fade" :class="{'show active': currentRouteName === 'transaction'}" id="v-transaction" role="tabpanel" aria-labelledby="transaction">
                                    <TransactionComponent></TransactionComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               </div>
               
            </div>
        </div>
    </section>
    <!-- Modal -->
    <div class="modal fade" id="newAddress" tabindex="-1" role="dialog" aria-labelledby="newAddressLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="newAddressLabel">Tambah Alamat</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeModalAddress">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body form-address">
                <div class="form-group">
                    <label>LABEL ALAMAT</label>
                    <div>
                    <input type="text" class="form-control" placeholder="ex: Rumah, Kantor" v-model="labelAlamat">
                    </div>
                </div>
                <div class="form-group mt-3">
                    <label>NAMA PENERIMA</label>
                    <div>
                    <input type="text" class="form-control" v-model="name_receiver">
                    </div>
                </div>
                <div class="form-group mt-3">
                    <label>NO. HP PENERIMA</label>
                    <div>
                    <input type="text" class="form-control" v-model="phone_receiver">
                    </div>
                </div>
                <div class="form-group mt-3">
                    <label>PILIh PROVINSI</label>
                    <div v-if="provinsi != ''">
                        <select class="form-control" v-model="selectedProvince" @change="getCity($event)">
                            <option></option>
                            <option v-for="province in provinsi" :key="province.province_id" :value="province.province_id">{{ province.province }}</option>
                        </select>
                    </div>
                    <div v-else>
                        <img src="/assets/images/loader2.gif" class="img-loader">
                    </div>
                </div>
                <div class="form-group mt-3" v-if="selectedProvince != ''">
                    <label>PILIH KOTA/KABUPATEN</label>
                    <div v-if="kota != ''">
                        <select class="form-control" v-model="selectedCity" @change.prevent="setCity($event)">
                            <option></option>
                            <option v-for="city in kota" :key="city.city_id" :value="city.city_id">{{ city.type+' '+city.city_name }}</option>
                        </select>
                    </div>
                    <div v-else>
                        <img src="/assets/images/loader2.gif" class="img-loader">
                    </div>
                </div>
                <div class="form-group mt-3" v-if="selectedCity != ''">
                    <label>DETAIL ALAMAT</label>
                    <div>
                    <textarea rows="4" class="form-control" v-model="detailAlamat" placeholder="Tulis detail seperti kode POS, nama jalan, nomor rumah, dan patokan jika ada"></textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <p class="text-warning" v-if="addressValidationError">Silahkan isi semua detail dengan lengkap !</p>
                <button type="button" class="btn btn-primary float-end" @click.prevent="addressValidation($event)">Save <i class="fa-solid fa-save"></i></button>
            </div>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="updateAdress" tabindex="-1" role="dialog" aria-labelledby="updateAdressLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="updateAdressLabel">Update Alamat</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeModalEditAddress">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body form-address" v-if="!selectedAddress">
                <img src="/assets/images/loader2.gif" class="img-loader">
            </div>
            <div class="modal-body form-address" v-else>
                <div class="form-group">
                    <label>LABEL ALAMAT</label>
                    <div>
                    <input type="text" class="form-control" v-model="selectedAddress.address_label" placeholder="ex: Rumah, Kantor">
                    </div>
                </div>
                <div class="form-group mt-3">
                    <label>NAMA PENERIMA</label>
                    <div>
                    <input type="text" class="form-control" v-model="selectedAddress.name_receiver">
                    </div>
                </div>
                <div class="form-group mt-3">
                    <label>NO. HP PENERIMA</label>
                    <div>
                    <input type="text" class="form-control" v-model="selectedAddress.phone_receiver">
                    </div>
                </div>
                <div class="form-group mt-3">
                    <label>PILIH PROVINSI</label>
                    <div v-if="provinsi != ''">
                        <select class="form-control" @change="getCityEdit($event)" v-model="selectedAddress.province_id">
                            <option></option>
                            <option v-for="province in provinsi" :key="province.province_id" :value="province.province_id">{{ province.province }}</option>
                        </select>
                    </div>
                    <div v-else>
                        <img src="/assets/images/loader2.gif" class="img-loader">
                    </div>
                </div>
                <div class="form-group mt-3" v-if="selectedAddress.province_id != ''">
                    <label>PILIH KOTA/KABUPATEN</label>
                    <div v-if="kota != ''">
                        <select class="form-control" @change.prevent="setCityEdit($event)" v-model="selectedAddress.city_id">
                            <option></option>
                            <option v-for="city in kota" :key="city.city_id" :value="city.city_id">{{ city.type+' '+city.city_name }}</option>
                        </select>
                    </div>
                    <div v-else>
                        <img src="/assets/images/loader2.gif" class="img-loader">
                    </div>
                </div>
                <div class="form-group mt-3" v-if="selectedAddress.city_id != ''">
                    <label>DETAIL ALAMAT</label>
                    <div>
                    <textarea rows="4" class="form-control" placeholder="Tulis detail seperti kode POS, nama jalan, nomor rumah, dan patokan jika ada" v-model="selectedAddress.detail_address"></textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <p class="text-warning" v-if="UpdateaddressValidationError">Silahkan isi semua detail dengan lengkap !</p>
                <button type="button" class="btn btn-primary float-end" @click.prevent="UpdateAddressValidation($event)">Save <i class="fa-solid fa-save"></i></button>
            </div>
            </div>
        </div>
    </div>

</template>
<script>
import $http from '../api';
import validator from 'validator';
import {mapGetters,mapActions } from "vuex";
import TransactionComponent from '@/components/TransactionComponent.vue'
export default {
    name: 'AccountView',
    components:{
        TransactionComponent
    },
    data(){
        return{
            name_receiver:'',
            phone_receiver:'',
            selectedProvince:'',
            provinceName:'',
            selectedCity:'',
            cityName:'',
            detailAlamat:'',
            labelAlamat:'',
            addressValidationError : false,
            selectedAddress:'',
            UpdateaddressValidationError:false,
            showInput: false,
            inputValue: ''
        }
    },
    mounted() {
        let oldScript=document.getElementById('mainJS');
        if(oldScript){
            oldScript.remove();
        }
        let mainJS = document.createElement('script')
        mainJS.setAttribute('id', 'mainJS')
        mainJS.setAttribute('src', '/assets/js/main.js')
        document.body.appendChild(mainJS);
    },
    methods:{
        ...mapActions({
            getProvince:"account/_setProvinsi",
            getKota:"account/_setKota",
            getAlamat:"account/getAlamat",
        }),
        pushRoute(route){
            history.pushState("", "Product Catalog", route);
        },
        async getCity(e){
            this.kota=''
            if(e.target.options.selectedIndex > -1) {
                this.provinceName=e.target.options[e.target.options.selectedIndex].text
            }else{
                this.provinceName=''
            }
            await this.getKota(this.selectedProvince)
        },
        setCity(e){
            if(e.target.options.selectedIndex > -1) {
                this.cityName=e.target.options[e.target.options.selectedIndex].text
            }else{
                this.cityName=''
            }
        },
        async getCityEdit(e){
            this.kota = ''
            if(e.target.options.selectedIndex > -1) {
                this.selectedAddress.province=e.target.options[e.target.options.selectedIndex].text
            }else{
                this.selectedAddress.province=''
            }
            await this.getKota(this.selectedAddress.province_id)
        },
        setCityEdit(e){
            if(e.target.options.selectedIndex > -1) {
                this.selectedAddress.city=e.target.options[e.target.options.selectedIndex].text
            }else{
                this.selectedAddress.city=''
            }
        },
        async addressValidation(e){
            e.target.disabled=true
            let validatePhone=validator.isMobilePhone(this.phone_receiver);
            if(this.idselectedProvince != '' && this.name_receiver != '' && validatePhone && this.selectedCity != '' && this.detailAlamat != '' && this.labelAlamat != ''){
                let form = {
                    name_receiver:this.name_receiver,
                    phone_receiver:this.phone_receiver,
                    province:this.provinceName,
                    province_id : this.selectedProvince,
                    city:this.cityName,
                    city_id: this.selectedCity,
                    detail_address:this.detailAlamat,
                    address_label: this.labelAlamat
                }
                this.addressValidationError = false
                let endpoint = 'address/submit'
                await $http.post(endpoint, form)
                .then((response) => {
                    if(response.status == 201){
                        this.$swal(
                        {
                            toast: true,
                            position: 'top-end',
                            icon: 'success',
                            title: 'Alamat berhasil update !!',
                            showConfirmButton: false,
                            timer: 3500
                            }
                        );
                    }else{
                        console.log(response)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
                document.getElementById('closeModalAddress').click()
                this.name_receiver='';
                this.phone_receiver='';
                this.selectedProvince='';
                this.provinceName='';
                this.selectedCity='';
                this.cityName='';
                this.detailAlamat='';
                this.labelAlamat='';
                this.addressValidationError = false;
                this.getAlamat();
            }else{
                this.addressValidationError = true
            }
            e.target.disabled=false
            
        },
        async editAddress(id){
            this.selectedAddress=''
            let endpoint = 'address/detail/'+id
            await $http.get(endpoint)
            .then((response) => {
                this.selectedAddress=response.data.message
                this.getKota(this.selectedAddress.province_id)
            })
            .catch((error) => {
                console.log(error)
            });
        },
        async UpdateAddressValidation(e){
            e.target.disabled=true
            let validatePhone=validator.isMobilePhone(this.selectedAddress.phone_receiver);
            if(this.selectedAddress.province_id != '' && this.selectedAddress.name_receiver != '' && validatePhone && this.selectedAddress.city_id != '' && this.selectedAddress.detail_address != '' && this.selectedAddress.address_label != ''){
                let form = {
                    name_receiver:this.selectedAddress.name_receiver,
                    phone_receiver:this.selectedAddress.phone_receiver,
                    province:this.selectedAddress.province,
                    province_id : this.selectedAddress.province_id,
                    city:this.selectedAddress.city,
                    city_id: this.selectedAddress.city_id,
                    detail_address:this.selectedAddress.detail_address,
                    address_label: this.selectedAddress.address_label
                }
                this.UpdateaddressValidationError = false
                let endpoint = 'address/update/'+this.selectedAddress.id
                await $http.put(endpoint, form)
                .then((response) => {
                    if(response.status == 201){
                        this.$swal(
                        {
                            toast: true,
                            position: 'top-end',
                            icon: 'success',
                            title: 'Alamat berhasil update !!',
                            showConfirmButton: false,
                            timer: 3500
                            }
                        );
                    }else{
                        console.log(response)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
                document.getElementById('closeModalEditAddress').click()
                this.getAlamat();
            }else{
                this.UpdateaddressValidationError = true
            }
            e.target.disabled=false
            
        },
        deleteAddress(id){
            this.$swal.fire({
                title: 'Apakah anda yakin?',
                text: "Akan menghapus alamat ini",
                icon: 'question',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'swal-confirm',
                    cancelButton: 'swal-cancel'
                }
                }).then((result) => {
                if (result.isConfirmed) {
                    let endpoint = 'address/delete/'+id
                    $http.delete(endpoint)
                    .then((response) => {
                        if(response.status == 200){
                            this.$swal(
                            {
                                toast: true,
                                position: 'top-end',
                                icon: 'success',
                                title: 'Alamat berhasil hapus !!',
                                showConfirmButton: false,
                                timer: 3500
                                }
                            );
                            this.getAlamat();
                        }else{
                            console.log(response)
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                }
            })
        },

        async updateProfile() {
            if(this.user_info.name != "" && this.user_info.phone != ""){
                let form = {
                    name: this.user_info.name,
                    phone: this.user_info.phone,
                }
                let endpoint = "profile/update";
                await $http.put(endpoint, form)
                .then((response) => {
                    if(response.status == 200){
                        let user_info = {
                            name: response.data.detail.name,
                            email: response.data.detail.email,
                            phone: response.data.detail.phone,
                            image: response.data.detail.image,
                            image_gcs: response.data.detail.image_gcs,
                        }
                        localStorage.setItem('user_info', JSON.stringify(user_info))
                        this.$swal(
                            {
                                toast: true,
                                position: 'top-end',
                                icon: 'success',
                                title: 'Profile berhasil diperbarui !!',
                                showConfirmButton: false,
                                timer: 3500
                            }
                        );
                    }else{
                        console.log(response)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })

                this.showInput = false;
            }
        }
    },
    computed:{
        ...mapGetters({
                provinsi:"account/provinsi",
                kota:"account/kota",
                alamat:"account/alamat",
                user_info:"auth/user_info",
        }),
        currentRouteName() {
            return this.$route.name;
        },
    }
}
</script>
