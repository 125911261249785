<template>
    <div>
       <!--Footer section start-->
        <footer class="footer-section section bg-gray-3">

            <!--Footer Top start-->
            <div class="footer-top section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50 pb-55 pb-lg-35 pb-md-25 pb-sm-15 pb-xs-10">
                <div class="container">
                    <div class="row">

                        <!--Footer Widget start-->
                        <div class="footer-widget col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-40 mb-xs-35 wow fadeInDown" data-wow-delay="0.2s">
                            <h4 class="title"><span class="text">Oprational Hours</span></h4>
                            <p class="mb-15">{{ contact.oprational }}</p>
                            <h4 class="title mt-10"><span class="text">Follow Us :</span></h4>
                            <div class="social-media">
                                <a :href="(contact.instagram) ? contact.instagram : '#'"><i class="fa-brands fa-instagram"></i></a>
                                <a :href="(contact.facebook) ? contact.facebook : '#'"><i class="fa-brands fa-facebook"></i></a>
                                <a :href="(contact.tiktok) ? contact.tiktok : '#'"><i class="fa-brands fa-tiktok"></i></a>
                            </div>
                        </div>
                        <!--Footer Widget end-->


                        <!--Footer Widget start-->
                        <div class="footer-widget col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-40 mb-xs-35 wow fadeInDown" data-wow-delay="0.4s">
                            <h4 class="title"><span class="text">Quick Link</span></h4>
                            <ul class="ft-menu">
                                <li><router-link to="/account">My account</router-link></li>
                                <li><router-link to="/account">Order Tracking</router-link></li>
                                <!-- <li><router-link to="/about">About Us</router-link></li> -->
                                <li><router-link to="/contact">Contact Us</router-link></li>
                                <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                            </ul>
                        </div>
                        <!--Footer Widget end-->

                        <!--Footer Widget start-->
                        <div class="footer-widget text-lg-end text-start col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-40 mb-xs-35 wow fadeInDown" data-wow-delay="0.6s">
                            <h2>{{ contact.address }}</h2>
                            <a class="contact-text" :href="'mailto:'+contact.email" target="_blank">{{ contact.email }}</a>
                            <a :href="'tel:'+contact.phone" class="contact-text">{{ contact.phone }}</a>
                        </div>
                        <!--Footer Widget end-->
                         <div class="footer-widget text-lg-end text-start col-12 mb-20 mb-xs-35">
                            <div class="payment-getway text-center">
                                <img src="/assets/images/pilihan-metode-pembayaran-memoire.png" alt="">
                            </div>
                         </div>
                    </div>
                </div>
            </div>
            <!--Footer Top end-->

            <!--Footer bottom start-->
            <div class="footer-bottom section">
                <div class="container">
                    <div class="row no-gutters">
                        <div class="col-12 ft-border pt-25 pb-25">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="copyright text-start">
                                        <p class="copyright">&copy; <span v-text="year"></span> <strong>{{ contact.name }}</strong> All Rights Reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Footer bottom end-->
            <a :href="'https://wa.me/'+contact.whatsapp+'?text=Hallo%2C%0AQiandra'" target="_blank" class="chat-widget"><i class="fa-brands fa-whatsapp"></i></a>

        </footer>
    </div>
</template>
<script>
import $http from '../api';
export default {
    name: 'FooterComponent',
    data() {
        return {
            year: new Date().getFullYear(),
            contact:''
        };
    },
    mounted(){
        this.getContactContent();
    },
    methods:{
        async getContactContent(){
            let endpoint = 'content/contact'
            await $http.get(endpoint)
                .then((response) => {
                    this.contact=response.data.message;
                })
                .catch((error) => {
                    console.log(error)
                });
        },
    }
}
</script>
