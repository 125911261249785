<template>
    <div>
          <!--Header section start-->
        <header class="header header-transparent header-sticky  d-lg-block d-none">
            <div class="header-deafult-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-3 col-lg-2 col-md-4 col-12">
                            <!--Logo Area Start-->
                            <div class="logo-area">
                                <router-link to="/"><img src="/assets/images/qiandra.png" alt=""></router-link>
                            </div>
                            <!--Logo Area End-->
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-4 d-none d-lg-block col-12">
                            <!--Header Menu Area Start-->
                            <div class="header-menu-area text-center">
                                <nav class="main-menu">
                                    <ul>
                                        <li :class="{ active: currentRouteName==='home' }">
                                            <router-link to="/">Home</router-link>
                                        </li>
                                        <li :class="{ active: currentRouteName==='shop' || currentRouteName==='product' || currentRouteName==='shop-by-category' }"><router-link to="/shop">Shop</router-link>
                                            <!-- <ul class="mega-menu two-column left-0">
                                                <li><a href="#" class="item-link">Essential Oils</a>
                                                    <ul>
                                                        <li><a href="#">Blends</a></li>
                                                        <li><a href="#">Single</a></li>
                                                    </ul>
                                                </li>
                                                <li><a href="#" class="item-link">Family Care</a>
                                                    <ul>
                                                        <li><a href="#">Deep Sleep</a></li>
                                                        <li><a href="#">Cough & Flu</a></li>
                                                    </ul>
                                                </li>
                                            </ul> -->
                                        </li>
                                        <li :class="{ active: currentRouteName==='about' }">
                                            <router-link to="/about">About</router-link>
                                        </li>
                                        <li :class="{ active: currentRouteName==='company' }">
                                            <router-link to="/company">Corporate Area</router-link>
                                        </li>
                                        <li :class="{ active: currentRouteName==='contact' }">
                                            <router-link to="/contact">Contact</router-link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <!--Header Menu Area End-->
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-5 col-12">
                            <!--Header Search And Mini Cart Area Start-->
                            <div class="header-search-cart-area">
                                <ul>
                                    <li style="position:relative">
                                        <div class="search-box">
                                            <div id="searchBox">
                                                <form @submit.prevent="search()">
                                                    <input type="text" placeholder="Search.." v-model="query" />
                                                </form>
                                                <a href="#" @click.prevent="" id="searchIcon"><i class="fa fa-search"></i></a>
                                            </div>
                                        </div>
                                    </li>
                                    <!-- <li><a class="header-search-toggle" href="#"><i class="flaticon-magnifying-glass"></i></a></li> -->
                                    <!-- <li class="currency-menu"><a href="#"><i class="flaticon-user"></i></a> -->
                                        <!--Crunccy dropdown-->
                                        <!-- <ul class="currency-dropdown"> -->
                                            <!--Account Currency Start-->
                                            <!-- <li>
                                                <a href="#">{{ (isAuth) ? user_info.name : 'My account' }}</a>
                                                <ul>
                                                    <li v-if="!isAuth"><router-link to="/login">Login</router-link></li>
                                                    <li v-if="!isAuth"><router-link to="/register">Register</router-link></li>
                                                    <li v-if="isAuth"><router-link to="/account">My account</router-link></li>
                                                    <li v-if="isAuth"><a href="#" @click.prevent="actionLogout()">Logout</a></li> -->
                                                    <!-- <li><a href="wishlist.html">Wishlist</a></li> -->
                                                <!-- </ul>
                                            </li> -->
                                            <!--Account Currency End-->
                                        <!-- </ul> -->
                                        <!--Crunccy dropdown-->
                                    <!-- </li> -->
                                    <li class="mini-cart">
                                         <!-- <router-link to="/cart"><i class="flaticon-shopping-cart"></i> <span class="badge mini-cart-total" style="padding:0; padding-top:3px;" v-if="totalCart">{{ (totalCart > 99) ? '99+' : totalCart }}</span></router-link> -->
                                        <!--Mini Cart Dropdown Start-->
                                        <div class="header-cart px-0">
                                            <div class="position-relative" :style="isLoadingHeader?'min-height:50px;':''">
                                                <ul class="cart-items">
                                                    <li class="single-cart-item px-4" v-for="item in cartData" :key="item.recid_product">
                                                        <div class="cart-img">
                                                            <router-link :to="'product/'+item.slug">
                                                                <img :src="item.image_gcs.image1" class="cart-thumbnail" :alt="item.slug">
                                                            </router-link>
                                                        </div>
                                                        <div class="cart-content">
                                                            <h5 class="product-name" data-bs-toggle="tooltip" data-bs-placement="top" :title="item.name"><a href="single-product.html">{{ item.name }}</a></h5>
                                                            <span class="product-quantity">{{ item.qty }} × </span>
                                                            <span class="product-price">Rp. {{ item.price.toLocaleString() }}</span>
                                                        </div>
                                                        <div class="cart-item-remove">
                                                            <a style="right: 20px;" title="Remove" href="#" @click.prevent="removeItemFromCart(item.recid_product)"><i class="fa fa-trash"></i></a>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div class="loading mt-0 overlay-header" v-if="isLoadingHeader">
                                                    <div class="d-flex justify-content-center w-100 h-100">
                                                        <img class="align-self-center" src="/assets/images/loading.gif">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="px-4">
                                                <!-- <div class="cart-total">
                                                    <h5>Total : <span class="float-right">Rp. {{ totalPrice.toLocaleString() }}</span></h5>
                                                </div> -->
                                                <div class="cart-btn">
                                                <router-link to="/cart">View Cart</router-link>
                                                </div>
                                            </div>
                                        </div>
                                        <!--Mini Cart Dropdown End-->
                                    </li>
                                </ul>
                            </div>
                            <!--Header Search And Mini Cart Area End-->
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <!--Header section end-->

        <header class="header-mobile header-sticky d-block d-lg-none">
            <div class="header-bottom menu-right">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="header-mobile-navigation d-block d-lg-none">
                                <div class="row align-items-center">
                                    <div class="col-6 col-md-6">
                                        <div class="header-logo">
                                            <a href="/">
                                                <img src="/assets/images/qiandra.png" class="img-fluid" alt="">
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="mobile-navigation text-end">
                                            <div class="header-icon-wrapper">
                                                <ul class="icon-list justify-content-end">
                                                    <!-- <li>
                                                        <div class="header-cart-icon">
                                                            <router-link to="/cart"><i class="flaticon-shopping-cart"></i><span class="badge mini-cart-total" style="padding:0; padding-top:3px;" v-if="totalCart">{{ (totalCart > 99) ? '99+' : totalCart }}</span></router-link>
                                                        </div>
                                                    </li> -->
                                                    <li>
                                                        <a href="javascript:void(0)" class="mobile-menu-icon" id="mobile-menu-trigger"><i class="fa fa-bars"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Mobile Menu start-->
                    <div class="row">
                        <div class="col-12 d-flex d-lg-none">
                            <div class="mobile-menu"></div>
                        </div>
                    </div>
                    <!--Mobile Menu end-->

                </div>
            </div>
        </header>

        <!-- Offcanvas Menu Start -->
        <div class="offcanvas-mobile-menu d-block d-lg-none" id="offcanvas-mobile-menu">
            <a href="javascript:void(0)" class="offcanvas-menu-close" id="offcanvas-menu-close-trigger">
                <i class="fa fa-times"></i>
            </a>

            <div class="offcanvas-wrapper">

                <div class="offcanvas-inner-content">
                    <div class="offcanvas-mobile-search-area">
                        <form @submit.prevent="search()">
                            <input type="search" v-model="query" placeholder="Search ...">
                            <button type="submit"><i class="fa fa-search"></i></button>
                        </form>
                    </div>
                    <nav class="offcanvas-navigation">
                        <ul>
                            <li :class="{ active: currentRouteName==='home' }">
                                <router-link to="/">Home</router-link>
                            </li>
                            <li :class="{ active: currentRouteName==='shop' || currentRouteName==='product' || currentRouteName==='shop-by-category' }"><router-link to="/shop">Shop</router-link>
                                <!-- <ul class="mega-menu two-column left-0">
                                    <li><a href="#" class="item-link">Essential Oils</a>
                                        <ul>
                                            <li><a href="#">Blends</a></li>
                                            <li><a href="#">Single</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="#" class="item-link">Family Care</a>
                                        <ul>
                                            <li><a href="#">Deep Sleep</a></li>
                                            <li><a href="#">Cough & Flu</a></li>
                                        </ul>
                                    </li>
                                </ul> -->
                            </li>
                            <li :class="{ active: currentRouteName==='about' }">
                                <router-link to="/about">About</router-link>
                            </li>
                            <li :class="{ active: currentRouteName==='company' }">
                                <router-link to="/company">Corporate Area</router-link>
                            </li>
                            <li :class="{ active: currentRouteName==='contact' }">
                                <router-link to="/contact">Contact</router-link>
                            </li>

                        </ul>
                    </nav>

                    <!-- <div class="offcanvas-settings">
                        <nav class="offcanvas-navigation">
                            <ul>
                                <li class="menu-item-has-children"><a href="#">{{ (isAuth) ? user_info.name : 'MY ACCOUNT' }}</a>
                                    <ul class="submenu2">
                                        <li v-if="!isAuth"><router-link to="/login">Login</router-link></li>
                                        <li v-if="!isAuth"><router-link to="/register">Register</router-link></li>
                                        <li  v-if="isAuth"><router-link to="/account">My account</router-link></li>
                                        <li v-if="isAuth"><a href="#" @click.prevent="actionLogout()">Logout</a></li>
                                        <li><a href="wishlist.html">Wishlist</a></li>
                                    </ul>
                                </li>
                               
                            </ul>
                        </nav>
                    </div> -->

                    <div class="offcanvas-widget-area">
                        <div class="off-canvas-contact-widget">
                            <div class="header-contact-info">
                                <ul class="header-contact-info-list">
                                    <li><i class="fa-solid fa-phone"></i> <a href="tel://6287856212073">+62 8785 6212 073 </a></li>
                                    <li><i class="fa-regular fa-envelope"></i> <a href="mailto:contact@qiandra-aromatic.com">contact@qiandra-aromatic.com</a></li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

        </div>
        <!-- Offcanvas Menu End -->
    </div>
</template>
<script>
import $http from '../api';
import { mapGetters,mapActions } from "vuex";
export default {
    name: 'HeaderComponent',
    data(){
        return{
            cartData:[],
            query : (this.$route.query.search) ?? ''
        }
    },
    mounted(){
        let navbarJS = document.createElement('script')
        navbarJS.setAttribute('id', 'navbarJS')
        navbarJS.setAttribute('src', '/assets/js/navbar.js')
        document.body.appendChild(navbarJS);
        //jika login, ambil data cart dari db
        if(this.isAuth){
            this.cartDB()
        }
        this.setCartData()
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
        ...mapGetters({
            cart:"products/cart",
            isAuth:"auth/isAuth",
            user_info:"auth/user_info",
            isLoadingHeader:"products/isLoadingHeader"
        }),
        totalPrice() {
            return this.cart.reduce((a, b) => a + b.qty * b.price, 0);
        },
        totalCart() {
            return this.cart.reduce((a, b) => a + b.qty, 0);
        }
    },
    methods:{
        ...mapActions({
            removeItemFromCart:"products/removeItemFromCart",
            syncCart:"products/syncCart",
            getCartFromDb:"products/getCartFromDb",
            logout:"auth/logout",
            setLoadingHeader:"products/setLoadingHeader",
        }),
        async cartDB(){
            await this.getCartFromDb()
        },
        async syncCartData(){
            this.syncCart()
        },
        async getCartFromDbData(){
            this.getCartFromDb()
        },
        async setCartData(){
            this.setLoadingHeader(true)
            let recid_product=[]
            for(let x in this.cart){
                let item = this.cart[x]
                let id=item.recid_product;
                recid_product.push(id)
            }
            // if(recid_product.length > 0){
                let form = {
                    recid_product
                }
                let endpoint = 'carts'
                await $http.post(endpoint, form)
                    .then((response) => {
                        let list = [];
                        for(let x in this.cart){
                            let item = this.cart[x]
                            let id=item.recid_product;
                            item.price = response.data.message[id].price
                            item.name = response.data.message[id].name
                            item.slug = response.data.message[id].slug
                            item.image = response.data.message[id].image
                            item.image_gcs = response.data.message[id].image_gcs
                            list.push(item)
                        }
                        this.cartData=list
                        this.setLoadingHeader(false)
                    })
                    .catch((error) => {
                        console.log(error)
                });
            // } else {
            //     this.setLoadingHeader(false)
            // }
        },
        search(){
            if(this.currentRouteName == 'shop-by-category' || this.currentRouteName == 'shop'){
                this.$router.push('?search='+this.query)
            }else{
                this.$router.push('/shop?search='+this.query)
            }
        },
        actionLogout(){
            this.logout()
            this.$router.push('/login')
        }
    },
    watch: {
        cart:function () {
            this.setCartData()
            // this.syncCartData()
        }
    }
}
</script>
