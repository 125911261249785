import store from '@/store'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutPage from '../views/AboutPage.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import CorporateArea from '../views/CorporateArea.vue'
import ContactPage from '../views/ContactPage.vue'
import ProductView from '../views/ProductView.vue'
import ProductDetail from '../views/ProductDetail.vue'
import RegisterPage from '../views/RegisterPage.vue'
import LoginPage from '../views/LoginPage.vue'
import CartView from '../views/CartView.vue'
import CheckOut from '../views/CheckOut.vue'
import AccountView from '../views/AccountView.vue'
import PageNotFound from '../views/404.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutPage
  },
  {
    path: '/company',
    name: 'company',
    component: CorporateArea
  },
  {
    path: '/shop',
    name: 'shop',
    component: ProductView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactPage
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy
  },
  {
    path: '/shop/:category',
    name: 'shop-by-category',
    component: ProductView
  },
  {
    path: '/product/:slug',
    name: 'product',
    component: ProductDetail
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterPage
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartView
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckOut,
    meta: {
      needAuth: true
    }
  },
  {
    path: '/account',
    name: 'account',
    component: AccountView,
    meta: {
      needAuth: true
    }
  },
  {
    path: '/account/transaction',
    name: 'transaction',
    component: AccountView,
    meta: {
      needAuth: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    component: PageNotFound
  }
]
// ... 


const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.needAuth)) {
    let isAuth = store.getters['auth/isAuth'];
    if (!isAuth) {
      next({ name: 'login' })
    } else {
      next()
    }
  } else {
    next()
  }
})
// ...
export default router
