<template>
    <div>
        <!-- Single Product Section Start -->
        
        <div class="single-product-section section pt-60 pt-lg-40 pt-md-30 pt-sm-20 pt-xs-25 pb-100 pb-lg-80 pb-md-70 pb-sm-30 pb-xs-20">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="loading mt-5" v-if="isLoading">
                            <img src="/assets/images/loading.gif" >
                        </div>
                        <div class="shop-area" v-else>
                            <div class="row">
                                <div class="col-md-6 col-lg-5 pr-35 pr-lg-15 pr-md-15 pr-sm-15 pr-xs-15">
                                    <!-- Product Details Left -->
                                    <div class="product-details-left">
                                        <div class="product-details-images">
                                            <div class="lg-image" v-for="image in productImage" :key="image">
                                                <img v-if="image" :src="image" :alt="product.name">
                                                <a v-if="image" :href="image" class="popup-img venobox" data-gall="myGallery"><i class="fa fa-expand"></i></a>
                                            </div>
                                        </div>
                                        <div class="product-details-thumbs">
                                            <div class="sm-image" v-for="thumb in productImage" :key="thumb"><img :src="thumb" :alt="product.name" v-if="thumb"></div>
                                        </div>
                                    </div>
                                    <!--Product Details Left -->
                                </div>
                                <div class="col-md-6 col-lg-7">
                                    <!--Product Details Content Start-->
                                    <div class="product-details-content">
                                       
                                        <h2>{{ product.name }}</h2>
                                        <div class="single-product-reviews">
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <a class="review-link" href="#">(1 customer review)</a>
                                        </div>
                                        <div class="single-product-price">
                                            <div class="price new-price">Rp. {{ product.discount_type > 0 ? (product.price-(product.price*product.discount/100)).toLocaleString() :(product.price-product.discount).toLocaleString() }}</div>
                                            <div class="regular-price" v-if="product.discount > 0">
                                                Rp. {{ product.price.toLocaleString() }}
                                                <span class="product-label">-{{ product.discount_type > 0 ? product.discount : (product.discount/product.price*100).toFixed() }}%</span>
                                            </div>
                                        </div>
                                        <div class="product-meta">
                                            <div class="posted-in">
                                                <span>Categories</span>
                                                <a href="#" v-if="productCategory">{{ productCategory }}</a>
                                            </div>
                                            <div class="posted-in">
                                                <span>Stock</span>
                                                <a href="#">{{ product.stock }}</a>
                                            </div>
                                        </div>
                                        <div class="single-product-quantity mt-20">
                                            <div class="row">
                                                <!-- <div class="col-6">
                                                    <div class="input-group input-qty mb-3">
                                                        <button class="btn" type="button" @click.prevent="minQty()"><i class="fa fa-minus"></i></button>
                                                        <input type="number" v-model="qty">
                                                        <button class="btn" type="button" @click.prevent="qty++"><i class="fa fa-plus"></i></button>
                                                    </div>
                                                    <button class="btn btn-add-to-cart" @click="addToCart(product)"><i class="fa fa-shopping-bag"></i>add to cart</button>
                                                </div> -->
                                                <div class="col-12">
                                                    <div class="ecommerce-link" v-if="product.tokped_link || product.shopee_link">
                                                        <span>Shop on</span>
                                                        <a :href="product.tokped_link" v-if="product.tokped_link" target="_blank" class="ecommerce"><img src="/assets/images/tokopedia-qiandra.png" alt="tokopedia essential oil"></a>
                                                        <a :href="product.shopee_link" v-if="product.shopee_link" target="_blank" class="ecommerce"><img src="/assets/images/shopee-qiandra.png" alt="tokopedia essential oil"></a>
                                                    </div>
                                                </div>
                                            </div>
                                                        
                                        </div>
                                        <div class="product-description mt-20" v-html="product.description">
                                            
                                        </div>
                                        <!-- <div class="wishlist-compare-btn">
                                            <a href="#" class="wishlist-btn">Add to Wishlist</a>
                                        </div> -->
                                        <!-- <div class="single-product-sharing">
                                            <h3>Share this product</h3>
                                            <ul>
                                                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                                <li><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                                <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                                <li><a href="#"><i class="fa fa-vimeo"></i></a></li>
                                            </ul>
                                        </div> -->
                                    </div>
                                    <!--Product Details Content End-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Single Product Section End -->
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: 'ProductDetail',
  data(){
    return{
        qty:1
    }
  },
  mounted() {
      let oldScript=document.getElementById('mainJS');
      if(oldScript){
        oldScript.remove();
      }
      let mainJS = document.createElement('script')
      mainJS.setAttribute('id', 'mainJS')
      mainJS.setAttribute('src', '/assets/js/main.js')
      document.body.appendChild(mainJS);
      document.title = 'Shop - Memoire';
      let productSlug = this.$route.params.slug;
      this.getProductDetails(productSlug);
  },
  computed: {
    ...mapGetters({
        product:"products/productDetails",
        productCategory:"products/productCategory",
        isLoading:"products/isLoading"
    }),
    productImage: function () {
       let images=[]
       let productImage=this.product.image_gcs
       for(var key in productImage){
        if(productImage[key] && productImage[key].includes('storage.googleapis.com/include_co')){
            images.push(productImage[key])
        }
       }
        return images
    },
  },
  methods: {
    ...mapActions({
        getProductDetails:"products/getProductDetails",
        addItemToCart:"products/addItemToCart"
    }),
    addToCart(item){
        item.qty=this.qty
        this.addItemToCart(item)
        this.alertSuccess()
    },
    alertSuccess() {
            this.$swal(
                {
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: 'Success adding product to cart',
                showConfirmButton: false,
                timer: 2500
                }
            );
    },
    minQty(){
        if(this.qty > 1){
            this.qty--
        }
    }

  }
}
</script>