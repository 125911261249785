<template>
    <section class="ds s-py-150">
        <div class="container">
            <div class="row mt-50 mb-150">
                <!--About Us Area Start-->
                <div class="about-us-area section pt-90 pt-lg-70 pt-md-60 pt-sm-50 pt-xs-45 pb-70 pb-lg-50 pb-md-60 pb-sm-60 pb-xs-50">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-12">
                                <!--About Us Image Start-->
                                <div class="about-us-img-wrapper mb-30 mb-xs-15">
                                    <div class="about-us-image img-full wow fadeInDown" data-wow-delay="0.2s">
                                        <a href="#"><img src="/assets/images/essential-oil-indonesia.jpg" alt=""></a>
                                    </div>
                                </div>
                                <!--About Us Image End-->
                            </div>
                            <div class="col-lg-6 col-12">
                                <!--About Us Content Start-->
                                <div class="about-us-content pl-30 pl-md-0 pl-sm-0 pl-xs-0">
                                    <span class="wow fadeInDown" data-wow-delay="0.4s">Since 2019</span>
                                    <h2 class="wow fadeInDown" data-wow-delay="0.6s">Semua Anugrah Kemurnian Alam</h2>
                                    <p class="wow fadeInDown" data-wow-delay="0.6s"><b>Qiandra Aromatic</b> berkomitmen menghasilkan produk Essential Oil dengan kualitas terbaik, sehingga semua orang dapat merasakan manfaat dari kemurnian yang di berikan alam.</p>
                                    <p class="wow fadeInDown" data-wow-delay="0.6s">Essential Oil yang di produksi oleh <b>Qiandra Aromatic</b> berasal dari fasilitas penyulingan dan lahan pertanian yang kami miliki sendiri. Selain itu kami juga bekerja sama dengan banyak petani dari berbagai daerah di indonesia ,sehingga kami turut serta berkontribusi untuk kemajuan pertanian dan keberlangsungan produksi Essential Oil di indonesia.</p>
                                </div>
                                <!--About Us Content End-->
                            </div>
                        </div>
                    </div>
                </div>
                <!--About Us Area End-->

                <!--Features section start-->
                <div class="features-section section pt-30 pt-lg-15 pt-md-0 pt-sm-0 pt-xs-15 mt-50">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-md-12 mt-50 mb-50">
                                <h2 class="text-center mb-20 section-title wow fadeInDown" data-wow-delay="0.2s">Produk Kami</h2>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <!-- Single Feature Start -->
                                <div class="single-feature mb-30 wow fadeInDown" data-wow-delay="0.4s">
                                    <div class="feature-image">
                                        <i class="fa-solid fa-leaf"></i>
                                    </div>
                                    <div class="feature-content">
                                        <h4 class="title">100% PURE AND NATURAL</h4>
                                        <p class="short-desc">Produk yang kami produksi terbuat dari 100% bahan alami yang memiliki segudang manfaat dan kegunaan</p>
                                    </div>
                                </div>
                                <!-- Single Feature End -->
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <!-- Single Feature Start -->
                                <div class="single-feature mb-30 wow fadeInDown" data-wow-delay="0.6s">
                                    <div class="feature-image">
                                        <i class="fa-solid fa-microscope"></i>
                                    </div>
                                    <div class="feature-content">
                                        <h4 class="title">TERUJI LABOLATORIUM</h4>
                                        <p class="short-desc">Kami selalu melakukan analisa di laboratorium untuk memastikan kandungan yang terdapat pada semua produk tetap terjaga kualitasnya</p>
                                    </div>
                                </div>
                                <!-- Single Feature End -->
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <!-- Single Feature Start -->
                                <div class="single-feature mb-30 wow fadeInDown" data-wow-delay="0.8s">
                                    <div class="feature-image">
                                    <i class="fa-solid fa-medal"></i>
                                    </div>
                                    <div class="feature-content">
                                        <h4 class="title">KUALITAS TERJAMIN</h4>
                                        <p class="short-desc">Bahan baku dari produk kami adalah minyak dengan kualitas terbaik yang sudah di terima dalam banyak industri dan pasar</p>
                                    </div>
                                </div>
                                <!-- Single Feature End -->
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-4 col-md-6 mt-50">
                                <!-- Single Feature Start -->
                                <div class="single-feature mb-30 wow fadeInDown" data-wow-delay="1s">
                                    <div class="feature-image">
                                        <i class="fa-solid fa-clipboard-check"></i>
                                    </div>
                                    <div class="feature-content">
                                        <h4 class="title">BEBAS BAHAN CAMPURAN</h4>
                                        <p class="short-desc">Kami tidak menggunakan bahan campuran apapun seperti pengencer dan bahan lainnya untuk semua produk Essential Oil</p>
                                    </div>
                                </div>
                                <!-- Single Feature End -->
                            </div>

                            <div class="col-lg-4 col-md-6 mt-50">
                                <!-- Single Feature Start -->
                                <div class="single-feature mb-30 wow fadeInDown" data-wow-delay="1.2s">
                                    <div class="feature-image">
                                    <i class="fa-solid fa-truck-droplet"></i>
                                    </div>
                                    <div class="feature-content">
                                        <h4 class="title">JANGKAUAN PENGIRIMAN YANG LUAS</h4>
                                        <p class="short-desc">Produk kami siap di kirimkan ke daerah mana pun baik di indonesia maupun seluruh dunia melalui berbagai metode dan sarana transportasi</p>
                                    </div>
                                </div>
                                <!-- Single Feature End -->
                            </div>
                        </div>
                    </div>
                </div>
                <!--Features section end-->
    
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'AboutPage',
    data(){
        return{
           
        }
    },
    mounted() {
        let oldScript=document.getElementById('mainJS');
        if(oldScript){
            oldScript.remove();
        }
        let mainJS = document.createElement('script')
        mainJS.setAttribute('id', 'mainJS')
        mainJS.setAttribute('src', 'assets/js/main.js')
        document.body.appendChild(mainJS);
    },
    methods:{
        
    },
    computed:{
        
    },
    watch: {
       
    },
}
</script>
