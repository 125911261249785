<template>
    <section class="ds s-py-150 error-404 not-found page_404 s-overlay mobile-overlay">
        <div class="container p-50">
            <div class="row mt-50 mb-50">
               <div class="col-md-6 d-none d-md-block">
                    <img src="/assets/images/register.svg" class="login-img" alt="">
               </div>
               <div class="col-md-6">
                    <div class="login-box">
                        <h3 class="text-center">Daftar Sekarang</h3>
                        <p class="text-center">Sudah punya akun Qiandra? <router-link to="/login">Login</router-link></p>
                        <form action="?" @submit.prevent="registerValidation">
                            <div v-if="errorMessage != ''" class="alert alert-warning alert-dismissible text-center" role="alert">
                                <div v-for="messageList in errorMessage" :key="messageList.key">
                                    <p v-for="message in messageList" :key="message.key">{{ message }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input type="email" id="formEmail" class="form-control" v-model="form.email" required placeholder="name@example.com">
                                        <span id="warningEmail" class="form-warning d-none">Format email tidak sesuai</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Nomor Hp</label>
                                        <input type="text" id="formPhone" placeholder="082123456789" class="form-control" v-model="form.phone" required>
                                        <span id="warningPhone" class="form-warning d-none">Format nomor HP tidak sesuai</span>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Nama Lengkap</label>
                                        <input type="text" id="formName" class="form-control" v-model="form.name" required>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Password</label>
                                        <input type="password" id="formPassword" class="form-control" v-model="form.password" required>
                                        <span id="warningPassword" class="form-warning d-none">Passwor minimal 8 Caracter </span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Ulangi Password</label>
                                        <input type="password" id="formpassword_confirmation" class="form-control" v-model="form.password_confirmation" required>
                                        <span id="warningPasswordConfirmation" class="form-warning d-none">Password tidak sama</span>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <button type="submit" class="btn form-control mt-20" id="btnRegister">Buat Akun</button>
                                </div>
                            </div>
                        </form>
                    </div>
               </div>
            </div>
        </div>
    </section>
</template>
<script>
import { mapActions,mapGetters } from "vuex";
import validator from 'validator';
export default {
    name: 'RegisterPage',
    data(){
            return{
                errorMessage:[],
                form : {
                    email:'',
                    phone:'',
                    name:'',
                    password:'',
                    password_confirmation:'',
                }
            }
    },
    mounted() {
        let oldScript=document.getElementById('mainJS');
        if(oldScript){
            oldScript.remove();
        }
        let mainJS = document.createElement('script')
        mainJS.setAttribute('id', 'mainJS')
        mainJS.setAttribute('src', 'assets/js/main.js')
        document.body.appendChild(mainJS);
    },
    computed: {
        ...mapGetters({
                StatusRegister:"auth/StatusRegister"
        }),
    },
    watch: {
        StatusRegister:function (val) {
            if(val.status == 201){ //sukser register
                this.$router.push('/login');
            }else if(val.status == 200){
                this.errorMessage=val.data.message
                console.log(val.data.message)
            }
        }
    },
    methods:{
        ...mapActions({
            register:"auth/register",
        }),
        registerValidation(){
            let formEmail=document.getElementById('formEmail');
            let formPhone=document.getElementById('formPhone');
            let formPassword=document.getElementById('formPassword');
            let formpassword_confirmation=document.getElementById('formpassword_confirmation');

            let validateEmail=validator.isEmail(this.form.email);
            let validatePhone=validator.isMobilePhone(this.form.phone);
            let validatePassword=validator.isLength(this.form.password,{min:8})
            let validatePasswordConfirmation=validator.equals(this.form.password_confirmation,this.form.password);

            if(!validateEmail){
                formEmail.classList.add('failed-validate');
                document.getElementById('warningEmail').classList.remove('d-none')
            }else{
                formEmail.classList.remove('failed-validate');
                document.getElementById('warningEmail').classList.add('d-none')
            }
            if(!validatePhone){
                formPhone.classList.add('failed-validate');
                document.getElementById('warningPhone').classList.remove('d-none')
            }else{
                formPhone.classList.remove('failed-validate');
                document.getElementById('warningPhone').classList.add('d-none')
            }
             if(!validatePassword){
                formPassword.classList.add('failed-validate');
                document.getElementById('warningPassword').classList.remove('d-none')
            }else{
                formPassword.classList.remove('failed-validate');
                document.getElementById('warningPassword').classList.add('d-none')
            }
            if(!validatePasswordConfirmation){
                document.getElementById('warningPasswordConfirmation').classList.remove('d-none')
                formpassword_confirmation.classList.add('failed-validate');
            }else{
                formpassword_confirmation.classList.remove('failed-validate');
                document.getElementById('warningPasswordConfirmation').classList.add('d-none')
            }

            if(validateEmail && validatePhone && validatePassword && validatePasswordConfirmation){
               document.getElementById('btnRegister').disabled=true;
               this.actionRegister()
            }
        },
        async actionRegister(){
            try{
                await this.register(this.form)
                localStorage.setItem('registerStatus', 'success')
                document.getElementById('btnRegister').disabled=false
            }catch(error){
                console.log(error)
                document.getElementById('btnRegister').disabled=false
            }
        }
    }
}
</script>
