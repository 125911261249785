<template>
    <section class="ds s-py-150">
        <div class="container p-50">
            <div class="row mt-50 mb-50">
               <div class="col-md-8">
                <h2 class="mb-50">Checkout</h2>
                <div>
                    <div class="form-group">
                        <label class="mb-10 w-100">
                            Pilih Alamat
                            <button class="btn btn-xs float-end" data-bs-toggle="modal" data-bs-target="#newAddress" @click="getProvince();">Tambah Alamat</button>
                        </label>
                        <div>
                                <select class="form-control"  v-model="selectedAddress" @change="selectAddress()">
                                    <option value="">Select</option>
                                    <option v-for="_alamat in alamat" :key="_alamat.id" :value="_alamat.id">{{ _alamat.address_label }}</option>
                                </select>
                        </div>
                        <div v-if="textAddress" class="mt-20">
                            <h4><b>{{ textAddress[0].name_receiver }}</b> <span class="address-phone-number">{{ textAddress[0].phone_receiver }}</span></h4>
                            <p>{{ textAddress[0].detail_address }} <br> {{ textAddress[0].city+' - '+textAddress[0].province }}</p>
                        </div>
                        <div class="mt-20">
                            
                        </div>
                    </div>
                    <div class="form-group" v-if="listOngkir || loadingOngkir">
                        <label class="mb-10 w-100">
                            Pilih Pengiriman
                        </label>
                        <div class="loading-sm" v-if="loadingOngkir">
                                <img src="/assets/images/loading.gif" >
                            </div>
                        <div v-else>
                            <select class="form-control" v-model="ongkir" @change="setCarrier($event)">
                                <option value="0">Pilih</option>
                                <option v-for="(ongkir,index) in listOngkir" :key="index" :value="ongkir.cost[0].value">JNE ({{ ongkir.service }}) - Rp. {{ ongkir.cost[0].value }} </option>
                            </select>
                        </div>
                    </div>
                </div>
               </div>
               <div class="col-md-4">
                    <div class="card card-checkout">
                        <div class="card-header">
                            <h4 class="text-center">Ringkasan Belanja</h4>
                        </div>
                        <div class="card-body">
                            <div class="loading mt-5 overlay" v-if="loadingSummary">
                                <img src="/assets/images/loading.gif" >
                            </div>
                            <table class="table" v-else>
                                <tr>
                                    <td>Total Harga ({{ summary.qty }} Barang)</td>
                                    <td class="text-end">Rp.{{ parseInt(summary.price).toLocaleString() }} </td>
                                </tr>
                                <tr>
                                    <td>Total Ongkir</td>
                                    <td class="text-end">{{ (ongkir > 0) ? 'Rp.'+parseInt(ongkir).toLocaleString() : '-' }}</td>
                                </tr>
                                <tr class="subtotal">
                                    <td>Subtotal</td>
                                    <td class="text-end">Rp.{{ countTotal.toLocaleString() }}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="card-footer">
                            <button @click="checkout()" class="btn btn-primary form-control" :disabled="ongkir == 0 || loadSelectPayment == true"> <i class="fa-solid fa-spinner fa-spin-pulse" v-if="loadSelectPayment == true"></i> Pilih Pembayaran</button>
                            <input type="hidden" id="snap-token" v-model="snapToken" class="form-input">
                        </div>
                    </div>
               </div>
            </div>
        </div>
    </section>
    <!-- Modal -->
    <div class="modal fade" id="newAddress" tabindex="-1" role="dialog" aria-labelledby="newAddressLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="newAddressLabel">Tambah Alamat</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeModalAddress">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body form-address">
                <div class="form-group">
                    <label>LABEL ALAMAT</label>
                    <div>
                    <input type="text" class="form-control" placeholder="ex: Rumah, Kantor" v-model="labelAlamat">
                    </div>
                </div>
                <div class="form-group mt-3">
                    <label>NAMA PENERIMA</label>
                    <div>
                    <input type="text" class="form-control" v-model="name_receiver">
                    </div>
                </div>
                <div class="form-group mt-3">
                    <label>NO. HP PENERIMA</label>
                    <div>
                    <input type="text" class="form-control" v-model="phone_receiver">
                    </div>
                </div>
                <div class="form-group mt-3">
                    <label>PILIh PROVINSI</label>
                    <div v-if="provinsi != ''">
                        <select class="form-control" v-model="selectedProvince" @change="getCity($event)">
                            <option></option>
                            <option v-for="province in provinsi" :key="province.province_id" :value="province.province_id">{{ province.province }}</option>
                        </select>
                    </div>
                    <div v-else>
                        <img src="/assets/images/loader2.gif" class="img-loader">
                    </div>
                </div>
                <div class="form-group mt-3" v-if="selectedProvince != ''">
                    <label>PILIH KOTA/KABUPATEN</label>
                    <div v-if="kota != ''">
                        <select class="form-control" v-model="selectedCity" @change.prevent="setCity($event)">
                            <option></option>
                            <option v-for="city in kota" :key="city.city_id" :value="city.city_id">{{ city.type+' '+city.city_name }}</option>
                        </select>
                    </div>
                    <div v-else>
                        <img src="/assets/images/loader2.gif" class="img-loader">
                    </div>
                </div>
                <div class="form-group mt-3" v-if="selectedCity != ''">
                    <label>DETAIL ALAMAT</label>
                    <div>
                    <textarea rows="4" class="form-control" v-model="detailAlamat" placeholder="Tulis detail seperti kode POS, nama jalan, nomor rumah, dan patokan jika ada"></textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <p class="text-warning" v-if="addressValidationError">Silahkan isi semua detail dengan lengkap !</p>
                <button type="button" class="btn btn-primary float-end" @click.prevent="addressValidation($event)">Save <i class="fa-solid fa-save"></i></button>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import $http from '../api';
import {mapGetters,mapActions } from "vuex";
import validator from 'validator';
export default {
    name: 'CheckOut',
    data(){
        return{
            selectedAddress:'',
            textAddress:'',
            listOngkir:'',
            ongkir:0,
            carrier:'',
            loadingSummary:false,
            loadingOngkir:false,
            loadSelectPayment:false,
            summary:[],
            snapToken:'',
            order_id:'',
            name_receiver:'',
            phone_receiver:'',
            selectedProvince:'',
            provinceName:'',
            selectedCity:'',
            cityName:'',
            detailAlamat:'',
            labelAlamat:'',
            addressValidationError : false,
        }
    },
    mounted() {
        let oldScript=document.getElementById('mainJS');
        if(oldScript){
            oldScript.remove();
        }
        let mainJS = document.createElement('script')
        mainJS.setAttribute('id', 'mainJS')
        mainJS.setAttribute('src', 'assets/js/main.js')
        document.body.appendChild(mainJS);
        this.getAlamat();
        this.getItemSummary();
        
    },
    methods:{
        ...mapActions({
            getAlamat:"account/getAlamat",
            getCartFromDb:"products/getCartFromDb",
            getProvince:"account/_setProvinsi",
            getKota:"account/_setKota",
        }),
        async getCity(e){
            this.kota=''
            if(e.target.options.selectedIndex > -1) {
                this.provinceName=e.target.options[e.target.options.selectedIndex].text
            }else{
                this.provinceName=''
            }
            await this.getKota(this.selectedProvince)
        },
        setCity(e){
            if(e.target.options.selectedIndex > -1) {
                this.cityName=e.target.options[e.target.options.selectedIndex].text
            }else{
                this.cityName=''
            }
        },
        async addressValidation(e){
            e.target.disabled=true
            let validatePhone=validator.isMobilePhone(this.phone_receiver);
            if(this.idselectedProvince != '' && this.name_receiver != '' && validatePhone && this.selectedCity != '' && this.detailAlamat != '' && this.labelAlamat != ''){
                let form = {
                    name_receiver:this.name_receiver,
                    phone_receiver:this.phone_receiver,
                    province:this.provinceName,
                    province_id : this.selectedProvince,
                    city:this.cityName,
                    city_id: this.selectedCity,
                    detail_address:this.detailAlamat,
                    address_label: this.labelAlamat
                }
                this.addressValidationError = false
                let endpoint = 'address/submit'
                await $http.post(endpoint, form)
                .then((response) => {
                    if(response.status == 201){
                        this.$swal(
                        {
                            toast: true,
                            position: 'top-end',
                            icon: 'success',
                            title: 'Alamat berhasil update !!',
                            showConfirmButton: false,
                            timer: 3500
                            }
                        );
                    }else{
                        console.log(response)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
                document.getElementById('closeModalAddress').click()
                this.name_receiver='';
                this.phone_receiver='';
                this.selectedProvince='';
                this.provinceName='';
                this.selectedCity='';
                this.cityName='';
                this.detailAlamat='';
                this.labelAlamat='';
                this.addressValidationError = false;
                this.getAlamat();
            }else{
                this.addressValidationError = true
            }
            e.target.disabled=false
            
        },
        async selectAddress(){
            if(this.selectedAddress != ''){
                this.loadingOngkir=true;
                this.textAddress = this.alamat.filter((item) => item.id === this.selectedAddress);
                let form = {
                    destination:this.textAddress[0].city_id,
                    weight:this.summary.weight
                }
                let endpoint = 'ongkir'
                await $http.post(endpoint,form)
                .then((response) => {
                   this.listOngkir=response.data.data
                   this.loadingOngkir = false;
                   this.ongkir=0
                })
                .catch((error) => {
                    console.log(error)
                    this.loadingOngkir = false;
                });
            }else{
                this.loadingOngkir=false;
                this.textAddress = '';
            }

        },
        async getItemSummary(){
            this.loadingSummary = true;
            let endpoint = 'carts/summary'
            await $http.post(endpoint)
            .then((response) => {
                this.summary=response.data.data
                this.loadingSummary = false;
            })
            .catch((error) => {
                console.log(error)
                this.loadingSummary = false;
            });

        },
        setCarrier(e){
            if(e.target.options.selectedIndex > -1) {
                this.carrier=e.target.options[e.target.options.selectedIndex].text
            }else{
                this.carrier=''
            }
        },
        async checkout(){
            this.loadSelectPayment=true
            let form = {
                address:this.selectedAddress,
                ongkir:this.ongkir,
                ammount:this.countTotal,
                carrier:this.carrier
            }
            let endpoint = 'checkout/payments'
            await $http.post(endpoint,form)
            .then((response) => {
                this.snapToken=response.data.data.token
                this.order_id=response.data.data.order_id
                this.showSnap()
            })
            .catch((error) => {
                console.log(error)
                this.loadSelectPayment = false;
            });
        },
        showSnap(){
            const self=this //harus di deklarasikan karna this tidak relative terhadap fungsi di bawah (scope)
             window.snap.pay(this.snapToken, {
                onPending: async function(result){
                    console.log(result)
                    let order_id=result.order_id;
                    let form = {
                        order_id:order_id,
                    }
                    let endpoint = 'transaction/select-payment'
                    await $http.post(endpoint,form)
                    .then(() => {
                        self.getCartFromDb()
                        self.loadSelectPayment=false
                        self.$router.push('/account/transaction')
                    })
                    .catch((error) => {
                        console.log(error)
                        self.loadSelectPayment = false;
                    });
                },
                onClose: async function(){ //on close , cancel transaction
                    let form = {
                        order_id:self.order_id,
                    }
                    let endpoint = 'transaction/cancel'
                    await $http.post(endpoint,form)
                    .then(() => {
                        self.loadSelectPayment=false
                        self.$router.push('/cart')
                    })
                    .catch((error) => {
                        console.log(error)
                        self.loadSelectPayment = false;
                    });
                }
            });
            
        }
    },
    computed:{
         ...mapGetters({
            alamat:"account/alamat",
            kota:"account/kota",
            provinsi:"account/provinsi",
        }),
        countTotal(){
            let total= parseInt(this.summary.price)+parseInt(this.ongkir);
            return total;
        }
        
    },
    watch: {
        
    },
}
</script>
<style scoped>
.table td{
    padding: 10px;
}
.subtotal{
    border-top: 1px solid #000;
    font-weight: bold;
}
</style>