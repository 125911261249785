<template>
    <section class="ds s-py-150 error-404 not-found page_404 s-overlay mobile-overlay">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <div class="page-content page-not-found">
                        <img src="/assets/images/404.svg" >
                        <h2 class="mb-15">oops...</h2>
                        <p class="fw-600">We're sorry, but something went wrong</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
  name: 'PageNotFound',
  mounted() {
      let oldScript=document.getElementById('mainJS');
      if(oldScript){
        oldScript.remove();
      }
      let mainJS = document.createElement('script')
      mainJS.setAttribute('id', 'mainJS')
      mainJS.setAttribute('src', 'assets/js/main.js')
      document.body.appendChild(mainJS);
  }
}
</script>
