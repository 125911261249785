<template>
    <div>
        <!--slider section start-->
        <div class="hero-section section position-relative">
            <div class="hero-slider section">

                <!--Hero Item start-->
                <div class="hero-item  bg-image" data-bg="assets/images/banner-qiandra.webp">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">

                                <!--Hero Content start-->
                                <div class="hero-content-2 text-md-end text-center pt-5">
                                    <h2 class="mt-5">ALL BENEFITS</h2>
                                    <h3>OF THE NATURE'S PURITY</h3>
                                    <router-link to="shop" class="btn">SHOP NOW</router-link>
                                </div>
                                <!--Hero Content end-->

                            </div>
                        </div>
                    </div>
                </div>
                <!--Hero Item end-->

                <!--Hero Item start-->
                <!-- <div class="hero-item bg-image" data-bg="assets/images/banner-4.jpg">
                    <div class="container">
                        <div class="row">
                            <div class="col-12"> -->

                                <!--Hero Content start-->
                                <!-- <div class="hero-content-2 center">
                                    <h3>New Product</h3>
                                    <h2>Essential Oil Blends</h2>
                                    <router-link to="shop" class="btn">SHOP NOW</router-link>
                                </div> -->
                                <!--Hero Content end-->

                            <!-- </div>
                        </div>
                    </div>
                </div> -->
                <!--Hero Item end-->

            </div>
        </div>
        <!--slider section end-->

        <!-- Banner section start -->
        <div class="banner-section section mt-md-150 mt-50 pt-30">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <!-- Single Banner Start -->
                        <div class="single-banner-item mb-30">
                            <div class="banner-image">
                                <a href="/shop">
                                    <img src="assets/images/post2.webp" class="wow fadeInDown" data-wow-delay="0.2s" alt="essential-oil-indonesia">
                                </a>
                            </div>
                        </div>
                        <!-- Single Banner End -->
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <h1 class="wow fadeInRight mt-5" data-wow-delay="0.4s"><b>Lebih dekat dengan<br>Essential Oil</b></h1>
                        <p class="mt-20 wow fadeInRight text-justify" data-wow-delay="0.6s">Essential Oil adalah minyak alami yang diekstrak dari satu jenis tanaman tertentu, biasanya minyak ini didapatkan dari akar, batang, daun, bunga dan buah-buahan. Minyak esensial atau minyak atsiri sendiri biasanya digunakan sebagai aromaterapi yang dipercaya memiliki berbagai manfaat untuk kesehatan tubuh. Selain itu, Essential juga di banyak di manfaatkan dalam berbagai industri seperti kesehatan, kecantikan, makanan, dan sebagainya karna memiliki beragam manfaat yang sudah teruji. </p>
                        <router-link to="/shop" class="btn-intro wow fadeInUp" data-wow-delay="0.6s">Saya Adalah Pengguna</router-link>
                        <router-link to="/company" class="btn-intro wow fadeInUp" data-wow-delay="0.6s">Saya Adalah Perusahaan</router-link>
                    </div>
                </div>
            </div>
        </div>
        <!-- Banner section End -->

        <!--Product section start-->
        <div class="product-section section pt-70 pt-lg-50 pt-md-40 pt-sm-30 pt-xs-20 pb-55 pb-lg-35 pb-md-25 pb-sm-15 pb-xs-5 mt-150">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="section-title text-center mb-50 wow fadeInDown" data-wow-delay="0.2s">
                            <h2>Best Picks</h2>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="isLoading">
                    <div class="loading mt-5">
                        <img src="/assets/images/loading.gif" >
                    </div>
                </div>
                <div class="row justify-content-center" v-else>
                    <div class="col-lg-3 col-md-4 col-6" v-for="product in products" :key="product.recid_product">
                        <!--  Single Grid product Start -->
                        <div class="single-grid-product mb-40 wow fadeInDown" data-wow-delay="0.2s">
                            <div class="product-image">
                                <div class="product-label">
                                    <span v-if="product.discount > 0">-{{ product.discount_type > 0 ? product.discount : (product.discount/product.price*100).toFixed() }}%</span>
                                </div>
                                <router-link :to="'product/'+product.slug" class="img-box">
                                    <img :src="product.image_gcs.image1" class="img-fluid" :alt="product.slug">
                                    <div class="img-placeholder"></div>
                                </router-link>

                                <div class="product-action">
                                    <ul>
                                        <!-- <li><a href="#" @click.prevent="addToCart(product)"><i class="fa fa-cart-plus"></i></a></li> -->
                                        <li v-if="product.tokped_link">
                                            <a :href="product.tokped_link" v-if="product.tokped_link" target="_blank"><img src="/assets/images/tokopedia-qiandra.png" alt="tokopedia essential oil"></a>
                                        </li>
                                        <li v-if="product.shopee_link">
                                            <a :href="product.shopee_link" v-if="product.shopee_link" target="_blank"><img src="/assets/images/shopee-qiandra.png" alt="tokopedia essential oil"></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="product-content">
                                <h3 class="title"> <router-link :to="'product/'+product.slug">{{ product.name }}</router-link></h3>
                                <p class="product-price">
                                    <span class="discounted-price">Rp. {{ product.discount_type > 0 ? (product.price-(product.price*product.discount/100)).toLocaleString() :(product.price-product.discount).toLocaleString() }}</span> 
                                    <span class="main-price discounted" v-if="product.discount > 0">Rp. {{ product.price.toLocaleString() }}</span>
                                </p>
                            </div>
                        </div>
                        <!--  Single Grid product End -->
                    </div>
                </div>
                <div class="text-center mt-50">
                    <router-link to="/shop" class="btn wow fadeInUp" data-wow-delay="0.2s" href="#">Show All Products</router-link>
                </div>
            </div>
        </div>
        <!--Product section end-->

        <!-- Banner section start -->
        <div class="banner-section section pb-40 pb-sm-30 pb-xs-0 mt-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <!-- Single Banner Start -->
                        <div class="single-banner-item pt-100 pt-md-80 pt-sm-70 pt-xs-50 pb-120 pb-md-100 pb-sm-90 pb-xs-50 mb-30 bg-image" data-bg="assets/images/qiandra-banner-2.webp">
                            <div class="sp-banner-content text-light">
                                <h2 class="title">DISCOUNT UP TO 50%</h2>
                                <h2 class="title">ALL ITEM</h2>
                                <span class="text-danger">LIMITED TIME OFER</span>
                                <div class="countdown-area mt-20">
                                    <div class="product-countdown" :data-countdown="endPromo"></div>
                                    <!-- <div class="discount-countdown">
                                        <span>10</span>
                                    </div>
                                    <div class="discount-countdown">
                                        <span>12</span>
                                    </div>
                                    <div class="discount-countdown">
                                        <span>30</span>
                                    </div> -->
                                </div>
                                <router-link to="shop">SHOP NOW</router-link>
                            </div>
                        </div>
                        <!-- Single Banner End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Banner section End -->


        <!-- Newsletter Section Start -->
        <div class="newsletter-section section bg-soft-green pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50 pb-95 pb-lg-75 pb-md-65 pb-sm-60 pb-xs-50">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="newsletter-content">
                            <h2>Subscribe Our Newsletter</h2>
                            <p>Dapatkan diskon spesial subscriber dan info promo mernarik dari kami.</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="newsletter-wrap">
                            <div class="newsletter-form">
                                <form class="mc-form" method="POST" action="/" @submit.prevent="checkSubscribe">
                                    <input type="text" placeholder="Email atau nomor telpon" v-model="subscriber" required>
                                    <button type="submit" value="submit">SUBSCRIBE!</button>
                                </form>
                            </div>
                            <!-- mailchimp-alerts Start -->
                            <div class="mailchimp-alerts">
                                <div class="mailchimp-submitting"></div><!-- mailchimp-submitting end -->
                                <div class="mailchimp-success"></div><!-- mailchimp-success end -->
                                <div class="mailchimp-error"></div><!-- mailchimp-error end -->
                            </div>
                            <!-- mailchimp-alerts end -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Newsletter Section End -->
        
        <!-- Button trigger modal -->
        <button type="button" class="d-none" data-bs-toggle="modal" id="subscribeModalBtn" data-bs-target="#subscribeModal"></button>
        <!-- Modal -->
        <form method="POST" action="/"  @submit.prevent="subscribe">
            <div class="modal fade" id="subscribeModal" tabindex="-1" aria-labelledby="subscribeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-sm">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="subscribeModalLabel">Required Information</h5>
                        <button type="button" class="btn-close" id="closeModalSubscribe" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group mb-3">
                            <label>Nama</label>
                            <input type="text" class="form-control" v-model="form.name" required>
                        </div>
                        <div class="form-group mb-3">
                            <label>Email</label>
                            <input type="email" class="form-control" v-model="form.email" required>
                        </div>
                        <div class="form-group mb-3">
                            <label>No. Tlp</label>
                            <input type="text" class="form-control" v-model="form.phone" required>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary form-control">Continue to Subscribe</button>
                    </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import $http from '../api';
import validator from 'validator';
import { mapActions, mapGetters } from "vuex";
export default {
  name: 'HomeView',
  data(){
    return{
        loginStatus: localStorage.getItem('loginStatus'),
        subscriber:'',
        form:{
            name:'',
            phone:'',
            email:''
        }
    }
  },
  mounted() {
      let oldScript=document.getElementById('mainJS');
      if(oldScript){
        oldScript.remove();
      }
      let mainJS = document.createElement('script')
      mainJS.setAttribute('id', 'mainJS')
      mainJS.setAttribute('src', 'assets/js/main.js')
      document.body.appendChild(mainJS);
      this.getProducts();
      if(this.loginStatus == 'success'){
            this.$swal(
                {
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: 'Login Berhasil, Selamat Datang !!',
                showConfirmButton: false,
                timer: 3500
                }
            );
            localStorage.removeItem('loginStatus')
      }
      
  },
  computed: {
    ...mapGetters({
        products:"products/dataHomeProducts",
        isLoading:"products/isLoading"
    }),
    endPromo(){
        var today = new Date().toISOString().slice(0, 10)
        var date= new Date(today)
        return date.setDate(date.getDate() + 1)
    }
  },
  methods: {
    ...mapActions({
        getProducts:"products/getHomeProducts",
        addItemToCart:"products/addItemToCart"
    }),
    addToCart(item){
        item.qty=1
        this.addItemToCart(item)
        this.alertSuccess()
    },
    alertSuccess() {
            this.$swal(
                {
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: 'Success adding product to cart',
                showConfirmButton: false,
                timer: 2500
                }
            );
    },
    checkSubscribe(){
        let input=this.subscriber;
        if(validator.isEmail(input) || validator.isMobilePhone(input)){
            if(validator.isMobilePhone(input)){
                this.form.phone=input
            }else{
                this.form.email=input
            }
           document.getElementById('subscribeModalBtn').click()
        }else{
            this.$swal(
            {
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: 'Format email atau nomor telpon tidak sesuai',
                showConfirmButton: false,
                timer: 3500
                }
            );
        }
    },
    async subscribe(){
        if(validator.isEmail(this.form.email) && validator.isMobilePhone(this.form.phone)){
            let form = {
                name:this.form.name,
                email:this.form.email,
                phone:this.form.phone,
            }
            let endpoint = 'subscribe'
            await $http.post(endpoint, form)
            .then((response) => {
                if(response.status == 201){
                    this.$swal(
                    {
                        toast: true,
                        position: 'top-end',
                        icon: 'success',
                        title: 'Subscribe berhasil !!',
                        showConfirmButton: false,
                        timer: 3500
                        }
                    );
                }else{
                    console.log(response)
                }
            })
            .catch((error) => {
                console.log(error)
            });
            document.getElementById('closeModalSubscribe').click()
            this.form.name =''
            this.form.phone =''
            this.form.email =''
            this.subscriber=''

        }else{
            this.$swal(
            {
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: 'Format email atau nomor telpon tidak sesuai',
                showConfirmButton: false,
                timer: 3500
                }
            );
        }
    }
  },
}
</script>