<template>
    <section class="ds s-py-150">
        <div class="container p-50">
            <div class="row mt-50 mb-50">
               <div class="col-md-8">
                <h2 class="mb-20">Cart Detail</h2>
                    <span v-if="pendingTransaction > 0" class="text-danger">Anda memiliki {{ pendingTransaction }} transaksi yang belum selesai, silahkan lakukan pembayaran pada transaksi sebelumnya untuk dapat checkout</span>
                    <div class="position-relative">
                        <table class="table cart-table">
                            <thead>
                                <tr>
                                    <th colspan="3"><span class="d-m-none">Item</span></th>
                                    <th class="text-center d-m-none" style="width:150px">Qty</th>
                                    <th class="d-m-none"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in cartData" :key="item.recid_product">
                                    <td>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" :checked="item.selected === 1" @change.prevent="selectItem(item.recid_product,$event)">
                                        </div>
                                    </td>
                                    <td>
                                        <router-link :to="'product/'+item.slug">
                                            <img :src="item.image_gcs.image1" class="cart-thumbnail" :alt="item.slug">
                                        </router-link>
                                    </td>
                                    <td>
                                        <router-link :to="'product/'+item.slug">{{ item.name }}</router-link>
                                        <div><b>Rp. {{ item.price.toLocaleString() }}</b></div>
                                        <div class="d-flex mt-4 d-md-none">
                                            <a href="#" class="mr-3" @click.prevent="removeItemFromCart(item.recid_product)"><i class="fa fa-trash"></i></a>
                                            <div class="cart-product-quantity  text-center px-3">
                                                <button class="btn" type="button" @click.prevent="item.qty--;updateCartQty(item.qty,item.recid_product,$event)" :disabled="item.qty===1"><i class="fa fa-minus"></i></button>
                                                <input type="number" v-model="item.qty" @input="updateCartQty(item.qty,item.recid_product,$event)">
                                                <button class="btn" type="button" @click.prevent="item.qty++;updateCartQty(item.qty,item.recid_product,$event)"><i class="fa fa-plus"></i></button>
                                            </div>
                                            <span class="alert-qty" v-if="item.qty < 1">Qty harus di isi</span>
                                        </div>
                                    </td>
                                    <td class="d-m-none">
                                        <div class="mt-20">
                                            <div class="cart-product-quantity  text-center mb-3">
                                                <button class="btn" type="button" @click.prevent="item.qty--;updateCartQty(item.qty,item.recid_product,$event)" :disabled="item.qty===1"><i class="fa fa-minus"></i></button>
                                                <input type="number" v-model="item.qty" @input="updateCartQty(item.qty,item.recid_product,$event)">
                                                <button class="btn" type="button" @click.prevent="item.qty++;updateCartQty(item.qty,item.recid_product,$event)"><i class="fa fa-plus"></i></button>
                                            </div>
                                            <span class="alert-qty" v-if="item.qty < 1">Qty harus di isi</span>
                                        </div>
                                    </td>
                                    <td class="d-m-none">
                                        <a href="#" @click.prevent="removeItemFromCart(item.recid_product)"><i class="fa fa-trash"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="loading mt-5 overlay" v-if="isLoading">
                            <img src="/assets/images/loading.gif" >
                        </div>
                    </div>
               </div>
               <div class="col-md-4">
                    <div class="card card-checkout">
                        <div class="card-header">
                            <h4 class="text-center">Total Belanja</h4>
                        </div>
                        <div class="card-body">
                            <div class="total-price">
                                Rp. {{ totalPrice.toLocaleString() }}
                            </div>
                        </div>
                        <div class="card-footer">
                            <router-link to="/checkout" v-if="totalPrice > 0 && pendingTransaction == 0" class="btn btn-primary form-control">Checkout</router-link>
                            <button v-else class="btn btn-primary form-control disabled" disabled>Checkout</button>
                        </div>
                    </div>
               </div>
            </div>
        </div>
    </section>
</template>
<script>
import $http from '../api';
import {mapGetters,mapActions } from "vuex";
export default {
    name: 'CartView',
    data(){
        return{
            cartData:[],
            selectStatus:0
        }
    },
    mounted() {
        let oldScript=document.getElementById('mainJS');
        if(oldScript){
            oldScript.remove();
        }
        let mainJS = document.createElement('script')
        mainJS.setAttribute('id', 'mainJS')
        mainJS.setAttribute('src', 'assets/js/main.js')
        document.body.appendChild(mainJS);
        //jika login, ambil data cart dari db
        if(this.isAuth){
            this.cartDB()
        }
        this.setCartData()
        this.getPendingTransaction()
    },
    methods:{
        ...mapActions({
            removeItemFromCart:"products/removeItemFromCart",
            selectItemAction:"products/selectItem",
            updateQty:"products/updateQty",
            setLoading:"products/setLoading",
            getCartFromDb:"products/getCartFromDb",
            getPendingTransaction:"account/getPendingTransaction",
        }),
        async cartDB(){
            await this.getCartFromDb()
        },
        async setCartData(){
            this.setLoading(true)
            let recid_product=[]
            for(let x in this.cart){
                let item = this.cart[x]
                let id=item.recid_product;
                recid_product.push(id)
            }
            let form = {
                recid_product
            }
            let endpoint = 'carts'
            await $http.post(endpoint, form)
                .then((response) => {
                    let list = [];
                    for(let x in this.cart){
                        let item = this.cart[x]
                        let id=item.recid_product;
                        item.price = response.data.message[id].discount_type > 0 ? response.data.message[id].price-(response.data.message[id].price * response.data.message[id].discount / 100) : response.data.message[id].price-response.data.message[id].discount
                        item.name = response.data.message[id].name
                        item.slug = response.data.message[id].slug
                        item.image = response.data.message[id].image
                        item.image_gcs = response.data.message[id].image_gcs
                        list.push(item)
                    }
                    this.cartData=list
                    this.setLoading(false)
                })
                .catch((error) => {
                    console.log(error)
            });
        },
        selectItem(id,e){
            if(e.target.checked){
                this.selectStatus=1
            }else{
                this.selectStatus=0
            }
            let item = {
                id:id,
                status:this.selectStatus
            }
            this.selectItemAction(item)
        },
        updateCartQty(qty,id,e){
            if(qty < 1){
                e.value= 1
            }else{
                let item={
                    qty:qty,
                    id:id
                }
                this.updateQty(item);
            }
        }
    },
    computed:{
         ...mapGetters({
            cart:"products/cart",
            isLoading:"products/isLoading",
            isAuth:"auth/isAuth",
            pendingTransaction:"account/pendingTransaction"
        }),
        totalPrice(){
            let selectedItem = this.cartData.filter((item) => item.selected === 1);
            let total = 0;
            for(let x in selectedItem){
                total += selectedItem[x].price*selectedItem[x].qty
            }
            return total;
        }
    },
    watch: {
        cart:function () {
             this.setCartData()
        }
    },
}
</script>
